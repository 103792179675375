import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Form } from 'react-bootstrap';
//errorBorder
function InputTextHook({
  type,
  name,
  label,
  register,
  mandatory,
  reqText,
  disabled,
  pattern,
  condition,
  errorBorder,
  placeholder,
  errors,
  compair,
  clickFun,
  value,
  depend,
  sts,
  checkMinor,
  changeFun,
}) {
  const [dateShow, setDateShow] = useState('1995-05-05');

  useEffect(() => {
    let today = new Date();
    let y = today.getFullYear() - 18;
    let m = today.getMonth();
    let d = today.getDate();
    let newDate = new Date(y + '-' + m + '-' + d);
    let ny = newDate.getFullYear();
    let nm = (newDate.getMonth() + 1).toString().padStart(2, '0');
    let nd = (newDate.getDate().toString().padStart(2, "0"));
    setDateShow(`${ny}-${nm}-${nd}`);
  },[]);

 

  return (
    <Form.Group className="mb-1">
      <Form.Label>
        {label}
        <span className="red">{mandatory}</span>
      </Form.Label>
      {/* for text compaireson */}
      {compair ? (
        <Form.Control
          type={type}
          className={` ${
            errorBorder ? 'border border-1 border-danger rounded-1' : null
          }`}
          {...register(name, {
            required: reqText,
            validate: (value) => value === compair || 'text does not match',

            pattern: pattern && {
              value: condition.value,
              message: condition.message,
            },
          })}
          autoComplete="nope"
          // disabled={disabled ? true : false}
        />
      ) : depend?.length ? (
        <Form.Control
          type={type}
          className={` ${
            errorBorder ? 'border border-1 border-danger rounded-1' : null
          }`}
          onClick={clickFun && clickFun}
          value={value && value}
          {...register(name, {
            required: reqText,
            disabled: sts,
            pattern: pattern && {
              value: condition.value,
              message: condition.message,
            },
          })}
          autoComplete="nope"
          // disabled={disabled ? true : false}
        />
      ) : (
        <Form.Control
          type={type}
          className={` ${
            errorBorder ? 'border border-1 border-danger rounded-1' : null
          }`}
          max={checkMinor ? dateShow : ''}
          onClick={clickFun && clickFun}
          value={value && value}
          {...register(name, {
            required: reqText,
            pattern: pattern && {
              value: condition.value,
              message: condition.message,
            },
          })}
          autoComplete="nope"
          // disabled={disabled ? true : false}
        />
      )}
    </Form.Group>
  );
}

export default InputTextHook;
