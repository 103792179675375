import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { useSearchParams } from 'react-router-dom';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { emptyCanCriteria } from '../can-criteria/canCriteriaSlice';
import { deletePrimaryHolderObj } from '../primary-holder/primarySlice';
import { deleteSecondHolderObj } from '../second-holder/SecondSlice';
import { deleteThirdHolderObj } from '../third-holder/thirdSlice';
import { deleteGuardianHolderObj } from '../guardian-holder/gurdianSlice';
import { emptyBankAccountOBJ } from '../bank-account/bankaccountSlice';
import { deleteNomineeObj, nomineeCountAction } from '../nominees/nomineeSlice';
import { pageCount, reset } from '../../reducer/Reducer/tab/tabSlice';

function SuccessPage() {
  const [canValue, setCanValue] = useState('');
  const [serchParam, setSerchParam] = useSearchParams();
  const canNumber = serchParam.get('can');
  const { canCredential } = useSelector(
    (state) => state.criteria
  );
 
  const [variant] = useState('Light');

  const dispatch = useDispatch();
 
  const { canId } =
    useSelector((state) => state.proof);
  
  const { submitted } =
    useSelector((state) => state.bankAccount);

  useEffect(() => {
    setCanValue(canId);
  }, []);

  useEffect(() => {
    if(canCredential?.skipNomineeVerification) {
      if(!submitted) {
        window.location.reload();
      } else {
        dispatch(emptyCanCriteria());
        dispatch(deletePrimaryHolderObj());
        dispatch(deleteSecondHolderObj());
        dispatch(deleteThirdHolderObj());
        dispatch(deleteGuardianHolderObj());
        dispatch(emptyBankAccountOBJ());
        dispatch(deleteNomineeObj());
        dispatch(reset());
      }
    }
  }, []);

  return (
    <Container className="col-md-5 mx-auto">
      <Card
        bg={variant.toLowerCase()}
        key={variant}
        text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
        style={{ marginTop: '30%' }}
        className="mb-"
      >
        {/* <Card.Header>Header</Card.Header> */}
        <Card.Body style={{ textAlign: 'center' }}>
          <Card.Title>
            <IoMdCheckmarkCircleOutline
              style={{ fontSize: '64px', color: 'green' }}
            />
          </Card.Title>
          <Card.Title>Thank You </Card.Title>
          <Card.Text style={{ color: '#777777', fontSize: '15px' }}>
            Your CAN request is successfully submitted and a provisional CAN{' '}
            <strong>{canValue || canNumber}</strong> is allotted. Please wait
            for the CAN to be Approved before initiating a transaction.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default SuccessPage;
