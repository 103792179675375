import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SmallMessageModal from '../modal/SmallMessageModal';

const tabName = [
  'CRI',
  'PRIM',
  'SEC',
  'THIR',
  'GUAR',
  'BANK',
  'NOMI',
  'PROO',
  'SUCC',
];

function useVerifyStepsSubmission() {
  const [formStepsStatus, setFormStepsStatus] = useState('');
  const [smShowTest, setSmShow] = useState(false);
  const { tabsCreater } = useSelector((state) => state.tab);
  const { canCriteriaObj, canCredential } = useSelector(
    (state) => state.criteria
  );
  const { primeHolderObj } = useSelector((state) => state.primary);
  const { secondHolderObj } = useSelector((state) => state.second);
  const { thirdHolderObj } = useSelector((state) => state.third);
  const { guardianHolderObj } = useSelector((state) => state.guardian);
  const { bankAccountsObj } = useSelector((state) => state.bankAccount);
  const { nomineeObj } = useSelector((state) => state.nominee);

  const filterTabs = tabsCreater
    .filter((item) => item.active === true)
    .slice(0, 1);


useEffect(() => {
  filterTabs.map((obj) => {
    if (obj.short === 'CRI') {
      if (Object.values(canCriteriaObj).length < 1) {
        setFormStepsStatus('please fill Can Criteria');
      }
      if (Object.values(canCredential).length < 1) {
        setFormStepsStatus('please fill Can Criteria arn');
      }
      setSmShow(true);
    }

    if (obj.short === 'PRIM') {
      if (Object.values(primeHolderObj).length < 1) {
        setSmShow(true);
        setFormStepsStatus('please fill Primary Holder');
      }
    }
    if (obj.short === 'SEC') {
      if (Object.values(secondHolderObj).length < 1) {
        setSmShow(true);
        setFormStepsStatus('please fill Second Holder');
      }
    }
    if (obj.short === 'THIR') {
      if (Object.values(thirdHolderObj).length < 1) {
        setSmShow(true);
        setFormStepsStatus('please fill Third Holder');
      }
    }
    if (obj.short === 'GUAR') {
      if (Object.values(guardianHolderObj).length < 1) {
        setSmShow(true);
        setFormStepsStatus('please fill Guardian Holder');
      }
    }
    if (obj.short === 'BANK') {
      if (bankAccountsObj.length < 1) {
        setSmShow(true);
        setFormStepsStatus('please fill Bank detail');
      }
    }

    // if (obj.short === 'NOMI') {
    //   if (Object.values(nomineeObj).length < 1) {
    //     setSmShow(true);
    //     setFormStepsStatus('please fill Nominee');
    //   }
    // }
  });
}, [filterTabs]);

  

  return { smShowTest, formStepsStatus };

  //   return (
  //     <React.Fragment>
  //       {smShow && (
  //         <SmallMessageModal
  //           smShow={smShow}
  //           setSmShow={setSmShow}
  //           title="Form Details"
  //           message={formStepsStatus}
  //         />
  //       )}
  //     </React.Fragment>
  //   );
}

export default useVerifyStepsSubmission;
