import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../Style.css';

//component
import Section from '../../common/section/Section';
import InputText from '../../common/form-elements/InputText';
import InputTextHook from '../../common/form-elements/InputTextHook';
import SelectOptionHook from '../../common/form-elements/SelectOptionHook';
import SelectSearchHook from '../../common/form-elements/SelectSearchHook';
import GridCustom from '../../common/grid-custom/GridCustom';
import SelectOption from '../../common/form-elements/SelectOption';
import { accountType, bankProofOptions } from './accountData';

const errorFontStyle = {
  color: 'red',
  fontSize: '12px',
  height: '12px',
  display: 'block',
};

function BankAccountSection({
  count,
  form,
  formObj,
  setForm,
  errorsOld,
  thisAccountHandeler,
  register,
  errors,
  setValue,
  watch,
  banksName,
  setBlanket,
  blanket,
  textRecords,
  setTextRecords,
  objLength,
  defaultBankHandeler,
  defaultBankIndex,
  number,
}) {
  let accountCount = count === 0 ? 'First' : count === 1 ? 'Second' : 'Third';
  const [dataLable, setDataLabel] = useState({});
  const [thisObj, setThisObj] = useState({});
  const [IsDefaultBank, setIsDefaultBank] = useState(true);

  useEffect(() => {
    setValue(`${accountCount}-accountNo`, formObj?.accountNo);
    setValue(`${accountCount}-reAccountNo`, formObj?.accountNo);
    setValue(`${accountCount}-accountType`, formObj?.accountType);
    setValue(`${accountCount}-bankId`, formObj?.bankId);
    setValue(`${accountCount}-micrCode`, formObj?.micrCode);
    setValue(`${accountCount}-ifscCode`, formObj?.ifscCode);
    setValue(`${accountCount}-bankProof`, formObj?.bankProof);
    // setValue(`${accountCount}-defaultAccountFlag`, formObj?.defaultAccountFlag === 'Y' ? true : false);
  }, []);

  let labelName = `${accountCount}-accountNo`;
  const accountNo = watch(labelName);
  const micrCode = watch(`${accountCount}-micrCode`);
  const ifscCode = watch(`${accountCount}-ifscCode`);

  useEffect(() => {
    setIsDefaultBank(formObj?.defaultAccountFlag === 'Y' ? true : false);
    
  }, [IsDefaultBank]);

  useEffect(() => {
    let findValue = banksName.find((item) => item.value === formObj?.bankId);
    setDataLabel(findValue);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      let micr = micrCode?.toUpperCase();
      let ifsc = ifscCode?.toUpperCase();

      setValue(`${accountCount}-micrCode`, micr);
      setValue(`${accountCount}-ifscCode`, ifsc);
    }, 400);

    return () => clearTimeout(timer);
  }, [micrCode, ifscCode]);

  useEffect(() => {
    let indexCount = textRecords.findIndex((items) => items.count === count);

    let updateRecords = [];

    if (indexCount !== -1) {
      updateRecords = textRecords
        .map((items) => {
          if (items.count === thisObj.count) {
            return { ...thisObj };
          }
          return items;
        })
        .filter((obj) => obj.value);
    } else {
      updateRecords = [...textRecords, thisObj];
    }

    setTextRecords(updateRecords);
  }, [thisObj]);

  

  // console.log('number =', +number, defaultBankIndex ,count);
  // console.log('objLength', !!objLength);

  return (
    <Section heading={`${accountCount} Bank Account details`}>
      <GridCustom>
        <Row>
          <Col xs={12} md={4}>
            <InputTextHook
              type="password"
              register={register}
              name={`${accountCount}-accountNo`}
              label="Bank A/c No"
              reqText="Bank A/c No required"
              disabled={false}
              errorBorder={errors[`${accountCount}-accountNo`]?.message}
              mandatory="*"
              pattern={true}
              condition={{
                value: /(^\d{1,16}$)/g,
                message: 'number required',
              }}
              // value={formObj?.accountNo || ''}
              // changeFun={formHandeler}
            />
            <small style={errorFontStyle}>
              {errors[`${accountCount}-accountNo`]?.message}
            </small>
          </Col>
          <Col xs={12} md={4}>
            <InputTextHook
              type="text"
              register={register}
              name={`${accountCount}-reAccountNo`}
              label="Re-Enter Bank A/c No"
              reqText="please re-enter Re-Enter Bank A/c No"
              disabled={false}
              errorBorder={errors[`${accountCount}-reAccountNo`]?.message}
              mandatory="*"
              // value={form?.confirmpanPekrnNo.toUpperCase() || ''}
              // changeFun={thisAccountHandeler}
              compair={accountNo}
            />
            <small style={errorFontStyle}>
              {errors[`${accountCount}-reAccountNo`]?.message}
            </small>
          </Col>
          <Col xs={12} md={4}>
            <SelectOptionHook
              register={register}
              // name="accountType"
              name={`${accountCount}-accountType`}
              label="Account Type"
              reqText="please select Account Type"
              disabled={false}
              mandatory="*"
              errorBorder={
                // !form?.otherDetail?.sourceOfWealth &&
                errors[`${accountCount}-accountType`]?.message
              }
              listOptions={accountType}
              // value={form?.otherDetail?.sourceOfWealth || ''}
              changeFun={thisAccountHandeler}
            />
            <small style={errorFontStyle}>
              {
                // !form?.otherDetail?.sourceOfWealth &&
                errors[`${accountCount}-accountType`]?.message
              }
            </small>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <SelectSearchHook
              register={register}
              // name="nationalityCountry"
              name={`${accountCount}-bankId`}
              selectFieldName={`bankId`}
              label="Bank"
              reqText="name required"
              mandatory="*"
              errorBorder={errors[`${accountCount}-bankId`]?.message}
              options={banksName}
              rowIndex={count}
              // value={form?.[fieldName[20]] || ''}
              value={
                banksName.find((item) =>
                  item.value === thisObj.value ? item.label : ''
                )?.label || dataLable?.label
              }
              setBlanket={setBlanket}
              blanket={blanket}
              // flag={form?.[`${accountCount}-bankId`]}
              thisObj={thisObj}
              setThisObj={setThisObj}
              form={form}
              setForm={setForm}
              // changeFun={formHandeler}
              setValue={setValue}
              sts={true}
              depend={`${accountCount}-bankId`}
              textRecords={textRecords}
              setTextRecords={setTextRecords}
            />
            <small style={errorFontStyle}>
              {errors[`${accountCount}-bankId`]?.message}
            </small>
          </Col>
          <Col xs={12} md={4}>
            <InputTextHook
              type="text"
              register={register}
              // name="accountNo"
              name={`${accountCount}-micrCode`}
              label="MICR"
              reqText="MICR required"
              disabled={false}
              errorBorder={errors[`${accountCount}-micrCode`]?.message}
              mandatory="*"
              pattern={true}
              condition={{
                value: /^[0-9]{9,}/g,
                message: 'min 9 digit required',
              }}

              // value={form?.name || ''}
              // changeFun={formHandeler}
            />
            <small style={errorFontStyle}>
              {errors[`${accountCount}-micrCode`]?.message}
            </small>
          </Col>
          <Col xs={12} md={4}>
            <InputTextHook
              type="text"
              register={register}
              // name="accountNo"
              name={`${accountCount}-ifscCode`}
              label="IFSC"
              reqText="IFSC required"
              disabled={false}
              errorBorder={errors[`${accountCount}-ifscCode`]?.message}
              mandatory="*"
              pattern={true}
              condition={{
                value: /^[a-zA-Z0-9-]{11,}/g,
                message: ' min 11 characters required',
              }}

              // value={form?.name || ''}
              // changeFun={formHandeler}
            />
            <small style={errorFontStyle}>
              {errors[`${accountCount}-ifscCode`]?.message}
            </small>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <SelectOptionHook
              register={register}
              // name="accountType"
              name={`${accountCount}-bankProof`}
              label="Bank Proof"
              reqText="please select Bank Proof"
              disabled={false}
              mandatory="*"
              errorBorder={
                // !form?.otherDetail?.sourceOfWealth &&
                errors[`${accountCount}-bankProof`]?.message
              }
              listOptions={bankProofOptions}
              // value={form?.otherDetail?.sourceOfWealth || ''}
              changeFun={thisAccountHandeler}
            />
            <small style={errorFontStyle}>
              {
                // !form?.otherDetail?.sourceOfWealth &&
                errors[`${accountCount}-bankProof`]?.message
              }
            </small>
          </Col>
          <Col xs={12} md={4} style={{ marginTop: '40px' }}>
            <Form.Check // prettier-ignore
              type="switch"
              id={`${accountCount}-custom-switch`}
              name={'defaultAccountFlag'}
              label="Default Bank"
              style={{ fontSize: '16px' }}
              checked={+number === 0 || defaultBankIndex === count ? true : false}
              // value={formObj?.defaultAccountFlag === 'Y' ? true : false}
              onChange={() => defaultBankHandeler(count)}
            />
          </Col>
        </Row>
      </GridCustom>
    </Section>
  );
}

export default BankAccountSection;
