import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  secondHolderObj: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  canId: '',
  taxResidency: 'N',
};

export const secondSlice = createSlice({
  name: 'second',
  initialState,
  reducers: {
    reset: (state) => {
      state.secondHolderObj = {};
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    changeTaxResidency: (state, action) => {
      state.taxResidency = action.payload;
    },
    deleteSecondHolderObj: (state, action) => {
      state.secondHolderObj = {};
    },

    createSecondHolderOBJ: (state, action) => {
      state.secondHolderObj = action.payload;
    },
  },
});

const { actions, reducer } = secondSlice;

export const {
  reset,
  createSecondHolderOBJ,
  changeTaxResidency,
  deleteSecondHolderObj,
} = actions;
export default reducer;
