import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bankAccountAPI from './bankAccountAPI';

const initialState = {
  bankAccountsObj: [],
  banksNameList: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  canId: '',
  accountCountNum: 1,
};

export const getBankNameAsync = createAsyncThunk(
  'bankAccount/getBank',
  async (__, thunkAPI) => {
    try {
      
      return await bankAccountAPI.getBanks();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const bankAccountSlice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    reset: (state) => {
      state.bankAccountsObj = [];
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    accountsFun: (state, action) => {
      state.accountCountNum = action.payload;
    },
    createBankAccountOBJ: (state, action) => {
      state.bankAccountsObj = action.payload;
    },
    emptyBankAccountOBJ: (state, action) => {
      state.bankAccountsObj = [];
    },
    isSubmitted: (state, action) => {
      state.submitted = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      //get bank proof
      .addCase(getBankNameAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBankNameAsync.fulfilled, (state, action) => {
        state.isLoading = false;

        state.banksNameList = action.payload;
        state.isError = false;
        state.message = '';
      })
      .addCase(getBankNameAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

const { actions, reducer } = bankAccountSlice;

export const { reset, accountsFun, createBankAccountOBJ, emptyBankAccountOBJ, isSubmitted } =
  actions;
export default reducer;
