import React, { useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import '../Style.css';
import { Container } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs';

// component
import GridCustom from '../../common/grid-custom/GridCustom';
import Section from '../../common/section/Section';
import InputText from '../../common/form-elements/InputText';
import FooterSection from '../../common/footerSection/FooterSection';
import { btnHandeler } from '../../common/helper/Helper';
import useCommonReducer from '../../common/customComp/useCommonReducer';
import { pageCount } from '../../reducer/Reducer/tab/tabSlice';
import SuccessMessage from '../../common/modal/SuccessMessage';
// import { useNavigate } from 'react-router-dom';
import UploadSection from './UploadSection';
import { BASE_URL } from '../../utils/constants';

import { proofUploadFun } from '../proof-upload/proofSlice';
import { isSubmitted } from '../bank-account/bankaccountSlice'

function ProofUpload() {
  const [btnFun, setBtnFun] = useState({});
  const [imagesList, setImagesList] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);
  const [recCanID, setRecCanID] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);
  const [nomineeApi, setNomineeApi] = useState([]);
  const [canNominee, setCanNominee] = useState([]);
  const [proofCount, setProofCount] = useState([]);
  const [varificationLink, setVarificationLink] = useState(false);
  const [url, setUrl] = useState({ primary: '', secondary: '', third: '' });
  const [modalShow, setModalShow] = React.useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const [status, setStatus] = useState(false);

  const { bankAccountsObj } = useSelector((state) => state.bankAccount);

  const { stepsCount, tabsCreater } = useSelector((state) => state.tab);
  const { canCriteriaObj, canCredential } = useSelector(
    (state) => state.criteria
  );
  const { primeHolderObj } = useSelector((state) => state.primary);
  const { secondHolderObj } = useSelector((state) => state.second);
  const { thirdHolderObj } = useSelector((state) => state.third);
  const { guardianHolderObj } = useSelector((state) => state.guardian);
  const { nomineeObj } = useSelector((state) => state.nominee);
  const { proofUploadObj, completeFormObj, canId, imageUploaded } = useSelector(
    (state) => state.proof
  );

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setRecCanID(canId);
  }, []);

  useEffect(() => {
    if (proofUploadObj.length) {
      setImagesList(proofUploadObj);
    }
  }, [proofUploadObj]);

  useEffect(() => {
    let banksName = bankAccountsObj.map((bank) => bank.bankId);
    setBankAccount(banksName);

    setCanNominee(nomineeObj.length);
    // console.log(nomineeObj);
    setRecCanID(canId);
  }, []);

  useEffect(() => {
    setBtnFun(btnHandeler(dispatch, pageCount, stepsCount));
  }, [dispatch, stepsCount]);

  const openLinkInNewTab = (property, linkName) => {
    window.open(linkName);
    // setUrl({...url, [property]:''});
  };

  useEffect(() => {
    let requestInterval;

    let dummyObj = {};
    if (canId !== '') {
      requestInterval = setInterval(async () => {
        let response = await fetch(`${BASE_URL}/cans/${canId}/status`, {
          method: 'get',
          headers: {
            'content-type': 'application/json',
          },
        });
        response = await response.json();

        if (response.code === 1) {
          dummyObj = {
            primary: response?.primaryHolderNomineeLink
              ? response?.primaryHolderNomineeLink
              : '',
            secondary: response?.secondHolderNomineeLink
              ? response?.secondHolderNomineeLink
              : '',
            third: response?.thirdHolderNomineeLink
              ? response?.thirdHolderNomineeLink
              : '',
          };
          setUrl({ ...dummyObj });
          setStatusSuccess(true);
        }

        if (
          dummyObj.primary === '' &&
          dummyObj.secondary === '' &&
          dummyObj.third === '' &&
          imageUploaded &&
          response.code === 1
        ) {
          localStorage.removeItem('persist:root');
          clearInterval(requestInterval);
          const shown = tabsCreater.filter((i) => i.show === true);
          dispatch(isSubmitted(false));
          dispatch(pageCount(shown.length - 1));
          if (firstTime) {
            setFirstTime(false);
            window.location.href =
              'https://mfu.finnsysonline.com/can-creation-success?can=' +
              canId +
              '&bankCount=' +
              bankAccountsObj?.length;
          } else {
            navigate({
              pathname: '/can-creation-success',
              search: `?${createSearchParams({
                can: canId,
                bankCount: bankAccountsObj?.length,
              })}`,
            });
          }

          // setModalShow(true);
        }
      }, 3000);
    }

    return () => {
      clearInterval(requestInterval);
    };
  }, []);

  useEffect(() => {
    if (proofCount.length > 0) {
      const even = (element) => element === true;

      let check = proofCount.every(even);

      if (check && proofCount.length === bankAccountsObj.length) {
        setVarificationLink(true);
        dispatch(proofUploadFun(true));
      }
    } else {
      setVarificationLink(false);
      dispatch(proofUploadFun(false));
    }
  }, [proofCount]);

  // useEffect(() => {
  //   if (canCredential?.skipNomineeVerification && imageUploaded) {
  //     localStorage.removeItem('persist:root');
  //     dispatch(pageCount(stepsCount + 1));
  //     navigate('can-creation-success');

  //   }
  // }, [imageUploaded]);

  return (
    <Container>
      {/* <SuccessMessage show={modalShow} /> */}

      {!modalShow && (
        <Form autoComplete="off">
          <Section heading="Proof Upload">
            <GridCustom>
              <Row>
                <Col xs={12}>
                  <Alert variant="danger">
                    Note: The allowed image file formats ( PNG, GIF, JPG | JPEG,
                    ). Total submitted document file size should not be more
                    than 500 KB.
                  </Alert>
                </Col>
              </Row>
              <Row className=" mb-4">
                <Col xs={12} md={6}>
                  <h5 className={canId ? 'text-success' : 'text-secondary'}>
                    Step 1: Can Criteria form submited &nbsp;
                    {canId && <BsCheckCircleFill />}
                  </h5>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <h5
                    className={
                      imageUploaded ? 'text-success' : 'text-secondary'
                    }
                    disabled
                  >
                    Step 2: Proof Upload &nbsp;
                    {imageUploaded && <BsCheckCircleFill />}
                  </h5>

                  {!imageUploaded &&
                    bankAccountsObj?.map((bank, index) => {
                      return (
                        // <div>{bank.bankId}</div>
                        <UploadSection
                          keyName={index + '-k'}
                          bank={bank}
                          recCanID={canId}
                          proofUploadObj={proofUploadObj}
                          setVarificationLink={setVarificationLink}
                          accountQty={bankAccountsObj.length}
                          proofCount={proofCount}
                          setProofCount={setProofCount}
                        />
                      );
                    })}
                </Col>
              </Row>
              {!canCredential?.skipNomineeVerification &&
                (url.primary || url.secondary || url.third) && (
                  <Row className=" mb-4">
                    <Col xs={12}>
                      <h5 className="text-secondary">
                        Step 3: Nominee Verification
                      </h5>

                      <div style={{ padding: '4px' }}>
                        {url.primary && (
                          <button
                            type="button"
                            className="btn btn-outline-success  btn-sm"
                            disabled={!imageUploaded}
                            onClick={() =>
                              openLinkInNewTab('primary', url.primary)
                            }
                          >
                            Click to verify Primary Holder
                          </button>
                        )}
                      </div>

                      <div style={{ padding: '4px' }}>
                        {url.secondary && (
                          <button
                            type="button"
                            className="btn btn-outline-success  btn-sm"
                            disabled={!imageUploaded}
                            onClick={() =>
                              openLinkInNewTab('secondary', url.secondary)
                            }
                          >
                            Click to verify Secondary Holder
                          </button>
                        )}
                      </div>
                      <div style={{ padding: '4px' }}>
                        {url.third && (
                          <button
                            type="button"
                            className="btn btn-outline-success  btn-sm"
                            disabled={!imageUploaded}
                            onClick={() => openLinkInNewTab('third', url.third)}
                          >
                            Click to verify Third Holder
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
            </GridCustom>
          </Section>
          {/* <button type="button" onClick={backBtnHandeler}>
            Back
          </button> */}
          {/* <FooterSection
        backBtn={recCanID ? false : true}
        nextBtn={false}
        submitBtn={false}
        btnFun={btnFun}
        cls="btn-right-align"
        <button
                  type="button"
                  onClick={addProofHandeler}
                  className="btn btn-outline-success  btn-sm"
                >
                  Add Proof
                </button>
      /> */}
        </Form>
      )}
    </Container>
  );
}

export default ProofUpload;
