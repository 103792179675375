import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  thirdHolderObj: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  canId: '',
  taxResidency: 'N',
};

export const thirdSlice = createSlice({
  name: 'third',
  initialState,
  reducers: {
    reset: (state) => {
      state.thirdHolderObj = {};
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    changeTaxResidency: (state, action) => {
      state.taxResidency = action.payload;
    },
    deleteThirdHolderObj: (state, action) => {
      state.thirdHolderObj = {};
    },

    createThirdHolderOBJ: (state, action) => {
      state.thirdHolderObj = action.payload;
    },
  },
});

const { actions, reducer } = thirdSlice;

export const {
  reset,
  createThirdHolderOBJ,
  changeTaxResidency,
  deleteThirdHolderObj,
} = actions;
export default reducer;
