import React from 'react';
import Button from 'react-bootstrap/Button';
import { BtnStyle, BtnTextStyle, BtnSubmitStyle } from './ButtonCustom-style';
import { MdArrowBackIosNew,MdArrowForwardIos } from 'react-icons/md';


function ButtonCustomNew({ text, backFun }) {
  return (
    <div
      style={{
        margin: '3px',
        marginBottom: text === 'next' || text === 'Submit' ? '60px' : null,
      }}
    >
      {text === 'next' ? (
        // <BtnStyle type="submit">
        //   <MdArrowForwardIos />
        // </BtnStyle>
        <Button type="submit" variant="outline-primary" size="md">
          Next
        </Button>
      ) : text === 'Submit' ? (
        <Button variant="success" type="submit" size="md">
          Submit
        </Button>
      ) : (
        // <BtnStyle type="button" onClick={backFun}>
        //   <MdArrowBackIosNew />
        // </BtnStyle>
        <Button variant="outline-primary" onClick={backFun} size="md">
          Back
        </Button>
      )}
    </div>
  );
}

export default ButtonCustomNew;

//  {
//    text === 'save' ? (
//      <BtnTextStyle type="submit">Save</BtnTextStyle>
//    ) : text === 'next' ? (
//      <BtnStyle onClick={myFun}>
//        <MdArrowForwardIos />
//      </BtnStyle>
//    ) : (
//      <BtnStyle onClick={myFun}>
//        <MdArrowBackIosNew />
//      </BtnStyle>
//    );
//  }
