import axios from 'axios';
import { BASE_URL } from '../../utils/constants';

// POST
export const submitFormData = async (obj) => {
  const response = await axios.post(`${BASE_URL}/cans`, obj, {
    headers: {
      'Content-type': 'application/json',
    },
  });

  const data = await response.data;


  return data;
};

// POST
export const createProofData = async (obj) => {
  const response = await axios.post(`${BASE_URL}/proofUploadObj`, obj, {
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
  });
  const data = await response.data;

  return data;
};

// GET
export const getProofData = async (userId) => {
  const response = await axios.get(
    `${BASE_URL}/proofUploadObj/?userId=${userId}`
  );
  const data = await response.data[0];

  if (data) {
    return data;
  }
  return {};
};

// PUT
export const updateProofData = async (obj) => {
  const response = await axios.put(
    `${BASE_URL}/proofUploadObj/${obj.userId}`,
    obj.proofs,
    {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    }
  );
  const data = await response.data;

  return data;
};

const proofAPI = {
  createProofData,
  getProofData,
  updateProofData,
  submitFormData,
};
export default proofAPI;
