
import Modal from 'react-bootstrap/Modal';

function ImageSizeInfo({ smShow,setSmShow }) {
 

  return (
    <>
      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Upload Images Instruction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          Total uploaded images size should not be are more than 500 KB
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageSizeInfo;
