import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function CheckNavigate() {
  const [str, setStr] = useState(null);
  const [displayedTab, setDisplayedTab] = useState([]);

  const navigate = useNavigate();

  const { stepsCount, tabsCreater, openForm } = useSelector(
    (state) => state.tab
  );
  const {  canId } = useSelector(
    (state) => state.proof
  );
   const { bankAccountsObj } = useSelector(
    (state) => state.bankAccount
  );

  const location = useLocation();
  useEffect(() => {
    let filterTabs = tabsCreater.filter((tab) => tab.show === true);
    let currTabs = filterTabs.map((tab) => tab.short);

    setDisplayedTab(currTabs);
  }, [tabsCreater]);

  useEffect(() => {
    let displaySection = displayedTab[stepsCount];
    setStr(displaySection);
  }, [displayedTab, stepsCount, tabsCreater]);

  useEffect(() => {
    if (str === 'CRI' || openForm === 'CRI') {
      navigate('/can-criteria');
    }
    if (str === 'PRIM') {
      // navigate('success');
      navigate('primary-holder');
    }
    if (str === 'SEC') {
      navigate('second-holder');
    }
    if (str === 'THIR') {
      navigate('third-holder');
    }
    if (str === 'GUAR') {
      navigate('guardian-holder');
    }
    if (str === 'BANK') {
      navigate('bank-accounts');
    }
    if (str === 'NOMI') {
      navigate('nominees');
    }
    if (str === 'PROO') {
      navigate('proof-upload');
    }
    if (str === 'SUCC') {
      navigate({
        pathname: '/can-creation-success',
        search: `?${createSearchParams({
          can: canId,
          bankCount: bankAccountsObj?.length ,
        })}`,
      });
    }
  }, [str, navigate]);

  

  return <>{!str && <div>Loading...</div>}</>;
}

export default CheckNavigate;
