import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import criteriaAPI from './canCriteriaAPI';

const initialState = {
  canCriteriaObj: {},
  canCredential:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  canId: '',
};

export const canCriteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  reducers: {
    reset: (state) => {
      state.canCriteriaObj = {};
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    createCanCredential: (state, action) => {
      state.canCredential = action.payload;
    },
    createCanCriteria: (state, action) => {
      state.canCriteriaObj = action.payload;
    },
    emptyCanCriteria: (state, action) => {
      state.canCriteriaObj = {};
    },
  },
});

const { actions, reducer } = canCriteriaSlice;

export const { reset, createCanCredential,createCanCriteria, emptyCanCriteria } =
  actions;
export default reducer;
