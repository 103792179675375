export const gurdianFormFields = {
  'gurdian-name': '',
  'gurdian-dateOfBirth': '',
  'gurdian-panPekrnNo': '',
  'gurdian-panExemptFlag': '',
  // 'gurdian-confirmpanPekrnNo': '',
  'gurdian-mobileIsdCode': '',
  'gurdian-primaryMobileNo': '',
  'gurdian-primaryEmail': '',
  // 'gurdian-primaryMobileBelongsTo': 'A',
  // 'gurdian-primaryEmailBelongsTo': 'A',
  'gurdian-grossIncome': '',
  'gurdian-netWorth': '',
  'gurdian-netWorthDate': '',
  'gurdian-sourceOfWealth': '',
  'gurdian-sourceOfWealthOthers': '',
  'gurdian-occupation': '',
  'gurdian-occupationOthers': '',
  'gurdian-kraAddressType': '',
  'gurdian-pep': '',
  'gurdian-taxResidencyFlag': 'N',
  'gurdian-birthCity': '',
  'gurdian-birthCountry': '',
  'gurdian-citizenshipCountry': '',
  'gurdian-nationalityCountry': '',
  // 'gurdian-sequenceNo': 0,
  'gurdian-taxRecords': [
    {
      taxCountry: 'kk',
      taxReferenceNo: 'hh',
      identityType: 'll',
    },
  ],
  'gurdian-relationship': '',
  'gurdian-relationshipProof': '',
};




