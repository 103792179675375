import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
//components
import InputTextHook from '../form-elements/InputTextHook';
import SelectOptionHook from '../form-elements/SelectOptionHook';
import MobileOptionHook from '../form-elements/MobileOptionHook';
import SelectSearchHook from '../form-elements/SelectSearchHook';
import SelectSearchOption from '../form-elements/SelectSearchOption';
import Section from '../section/Section';
import InputText from '../form-elements/InputText';
import GridCustom from '../grid-custom/GridCustom';
import NonIndianResidence from './NonIndianResidence';

import FooterSection from '../footerSection/FooterSection';
import { btnHandeler } from '../helper/Helper';
import { pageCount } from '../../reducer/Reducer/tab/tabSlice';

import {
  sourceOfWealthOptions,
  occupationOptions,
  politicalExposureOptions,
  addressTypeOptions,
  taxResidencyOptions,
  countryListOptions,
  grossAnnualIncomeOptions,
  relationShipOptions,
  relationshipProofOptions,
} from './stakeHolderData';

const errorFontStyle = {
  color: 'red',
  fontSize: '12px',
  height: '12px',
  display: 'block',
};

function StakeHolder({
  form,
  setForm,
  holderType,
  errors,
  register,
  watch,
  setValue,
  getValues,
  errorsOld,
  setErrors,
  networthRadio,
  setNetworthRadio,
  grossIncomeRadio,
  setGrossIncomeRadio,
  fieldName,
  sliceData,
  IsPan,
  setIsPan,
  IsPekrn,
  setIsPekrn,
  taxResidency,
  changeTaxResidency,
  investorCategory,
  textRecords,
  setTextRecords,
  recordsObj,
}) {
  const [btnFun, setBtnFun] = useState({});
  const [isOtherSourceOfWealth, setIsOtherSourceOfWealth] = useState(true);
  const [isOtherOccupation, setIsOtherOccupation] = useState(true);
  const [notIndian, setNotIndian] = useState(true);
  const [IsTextCountry, setIsTextCountry] = useState(false);
  const [test, setTest] = useState(false);
  const [nonIndianList, setNonIndianList] = useState([]);
  const [isFormFilled, setFormFilled] = useState(false);
  const [IsDeviceMobile, setIsDeviceMobile] = useState(0);
  // const [isPan, setIsPan] = useState(true);
  // const [isPekrn, setIsPekrn] = useState(false);

  const { stepsCount } = useSelector((state) => state.tab);

  const dispatch = useDispatch();

  const [blanket, setBlanket] = useState(false);

  const closeBlanketHandeler = () => {
    setBlanket(false);
  };

  useEffect(()=>{

    setIsDeviceMobile(window.innerWidth)

  },[])


  useEffect(() => {
    if (blanket) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [blanket]);

  const formHandeler = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    

    //!form?.otherDetail?.sourceOfWealth
    // setForm({ ...form, [name]: val });

    if (name === fieldName[10]) {
      if (val === '08') {
        setIsOtherSourceOfWealth(false);
        errors[fieldName[10]] = '';
        // setForm({ ...form, otherDetail: { ...form.otherDetail, [name]: val } });
      } else {
        setIsOtherSourceOfWealth(true);

        setValue(fieldName[11], '');
      }
    }

    if (name === fieldName[12]) {
      if (val === '99') {
        setIsOtherOccupation(false);
        errors[fieldName[12]] = '';
      } else {
        setIsOtherOccupation(true);
        setValue(fieldName[13], '');
      }
    }

    if (name === fieldName[14]) {
      if (val !== '') {
        setTest(true);
        errors[fieldName[14]] = '';
      } else {
        setTest(false);
      }
    }

    if (name === fieldName[15]) {
      if (val !== '') {
        setTest(true);
        errors[fieldName[15]] = '';
      } else {
        setTest(false);
      }
    }

    if (name === fieldName[16]) {
      if (val === 'Y') {
        setNotIndian(false);
        setIsTextCountry(true);
        dispatch(changeTaxResidency('Y'));
      } else {
        setNotIndian(true);
        setIsTextCountry(false);
        setTextRecords([{ ...recordsObj }]);
        dispatch(changeTaxResidency('N'));
      }
    }
  };

  // radio btn show hide
  const incomeStatus = (e) => {
    let status = e.target.dataset.name;
    console.log(status);
    if (status === 'GAI') {
      setForm({
        ...form,

        otherInfo: '',
        [fieldName[8]]: '',
        [fieldName[9]]: '',
      });
      setGrossIncomeRadio(true);
      setNetworthRadio(false);
    } else {
      setForm({
        ...form,

        otherInfo: '',
        [fieldName[7]]: '',
      });
      setGrossIncomeRadio(false);
      setNetworthRadio(true);
    }
  };

  useEffect(() => {
    setBtnFun(btnHandeler(dispatch, pageCount, stepsCount));
  }, [dispatch, stepsCount]);

  const panPekrnNo = watch(fieldName[2]);

  useEffect(() => {
    if (sliceData?.taxResidencyFlag === 'Y') {
      setNotIndian(false);
    } else {
      setNotIndian(true);
    }

    if (Object.keys(sliceData)?.length > 0) {
      if (sliceData?.otherDetail.sourceOfWealthOthers) {
        setIsOtherSourceOfWealth(false);
      } else {
        setIsOtherSourceOfWealth(true);
        setValue(fieldName[11], '');
      }
      if (sliceData?.otherDetail.occupationOthers) {
        setIsOtherOccupation(false);
      } else {
        setIsOtherOccupation(true);
        setValue(fieldName[13], '');
      }
    }
  }, []);

  useEffect(() => {
    // setValue('name', form?.name);

    let formTypeName = fieldName[0].split('-')[0];

    if (form?.[`${formTypeName}-name`] && !isFormFilled) {
      for (let k in form) {
        setValue(k, form[k]);
      }
      setFormFilled(true);
    }
    if (sliceData.panPekrnNo) {
      setValue(`${fieldName[2]}-conf`, sliceData.panPekrnNo);
    }
  }, [form]);

  const addRecordRow = () => {
    setTextRecords([...textRecords, recordsObj]);
  };

  const removeRecordRow = (ind) => {
    const filterRow = textRecords.filter((_, index) => index !== ind);

    setTextRecords(filterRow);
  };

  const refPanField = watch(fieldName[2]);
  const refCompField = watch(`${fieldName[2]}-conf`);

  const panFun = () => {
    let panField = refPanField?.toUpperCase();
    let compField = refCompField?.toUpperCase();

    setValue(fieldName[2], panField);
    setValue(`${fieldName[2]}-conf`, compField);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      panFun();
    }, 400);
    return () => clearTimeout(timer);
  }, [refPanField, refCompField]);

  useEffect(() => {
    panFun();
  }, []);

  useEffect(() => {
    function updateSize() {
      setIsDeviceMobile(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
 

  return (
    <React.Fragment>
      <div>
        {blanket && (
          <div onClick={closeBlanketHandeler} className="blanket-cover"></div>
        )}
      </div>

      <Section heading={`${holderType} Basic Details`}>
        <GridCustom>
          <Row>
            <Col xs={12} md={8}>
              <Row>
                <Col xs={12} md={6}>
                  <InputTextHook
                    type="text"
                    register={register}
                    name={fieldName[0]}
                    label="Name"
                    reqText="name required"
                    disabled={false}
                    errorBorder={errors[fieldName[0]]?.message}
                    mandatory="*"
                    pattern={true}
                    condition={{
                      value: /^[\sa-zA-Z0-9-]{4,}$/gm,
                      message: 'min 4 charater required',
                    }}
                    // value={form?.name || ''}
                    // changeFun={formHandeler}
                  />
                  <small style={errorFontStyle}>
                    {errors[fieldName[0]]?.message}
                  </small>
                </Col>
                <Col xs={12} md={6}>
                  <InputTextHook
                    type="date"
                    register={register}
                    name={fieldName[1]}
                    label="Date of Birth"
                    reqText="date required"
                    disabled={false}
                    errorBorder={errors[fieldName[1]]?.message}
                    mandatory="*"
                    checkMinor={
                      investorCategory !== 'M' ||
                      fieldName[0].split('-')[0] === 'gurdian'
                    }
                  />
                  <small style={errorFontStyle}>
                    {errors[fieldName[1]]?.message}
                  </small>
                </Col>
              </Row>
              {investorCategory === 'M' &&
                fieldName[0].split('-')[0] === 'gurdian' && (
                  <Row>
                    <Col xs={12} md={6}>
                      <SelectOptionHook
                        register={register}
                        // name="grossIncome"
                        name={fieldName[22]}
                        label="Relationship "
                        reqText="please select Relationship"
                        mandatory="*"
                        errorBorder={errors[fieldName[22]]?.message}
                        listOptions={relationShipOptions}
                        changeFun={formHandeler}
                      />
                      <small style={errorFontStyle}>
                        {errors[fieldName[22]]?.message}
                      </small>
                    </Col>
                    <Col xs={12} md={6}>
                      <SelectOptionHook
                        register={register}
                        // name="grossIncome"
                        name={fieldName[23]}
                        label="Relationship Proof "
                        reqText="please select relationshipProof"
                        mandatory="*"
                        errorBorder={errors[fieldName[23]]?.message}
                        listOptions={relationshipProofOptions}
                        changeFun={formHandeler}
                      />
                      <small style={errorFontStyle}>
                        {errors[[fieldName[23]]]?.message}
                      </small>
                    </Col>
                  </Row>
                )}

              <Row
                style={{
                  marginTop:
                    IsDeviceMobile >= 768 &&
                    fieldName[0].split('-')[0] !== 'gurdian'
                      ? '70px'
                      : null,
                }}
              >
                <Col xs={12} md={6} style={{ display: 'flex' }}>
                  {' '}
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Mobile (ISD-Mobile)<span className="red">*</span>
                    </Form.Label>
                    <InputGroup>
                      <MobileOptionHook
                        register={register}
                        // name="mobileIsdCode"
                        name={fieldName[4]}
                        maxLength={2}
                        errorBorder={errors[fieldName[4]]?.message}
                        // value={form?.mobileIsdCode || ''}
                        // changeFun={formHandeler}
                        reqText="ISD code required"
                        pattern={true}
                        condition={{
                          value: /^\d{2}?$/gm,
                          message: '2 digit number required',
                        }}
                        boxWidth={'20%'}
                      />
                      <MobileOptionHook
                        register={register}
                        // name="primaryMobileNo"
                        name={fieldName[5]}
                        maxLength={10}
                        errorBorder={errors[fieldName[5]]?.message}
                        // value={form?.primaryMobileNo || ''}
                        // changeFun={formHandeler}
                        reqText="mobile no. required"
                        pattern={true}
                        condition={{
                          value: /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/g,
                          message: '10 digit number required',
                        }}
                        boxWidth={'80%'}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <InputTextHook
                    register={register}
                    // name="primaryEmail"
                    name={fieldName[6]}
                    label="Email"
                    reqText="email required"
                    disabled={false}
                    errorBorder={errors[fieldName[6]]?.message}
                    mandatory="*"
                    pattern={true}
                    condition={{
                      value:
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                      message: 'email is invalid',
                    }}
                    // value={form?.contactDetail.primaryEmail || ''}
                    // changeFun={formHandeler}
                  />
                  <small style={errorFontStyle}>
                    {errors[fieldName[6]]?.message}
                  </small>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <Row>
                <Col xs={12} md={12}>
                  {(investorCategory !== 'M' ||
                    fieldName[0].split('-')[0] === 'gurdian') && (
                    <div className="mb-3">
                      <div>
                        <small className=" text-muted">
                          Do you have a PAN?
                        </small>
                      </div>
                      <Form.Check
                        inline
                        label="Yes"
                        name="group1"
                        type={'radio'}
                        id={`inline-radio-1`}
                        style={{ fontSize: '13px' }}
                        checked={IsPan}
                        // value={IsPan}
                        onChange={() => {
                          setIsPan(!IsPan);
                        }}
                      />
                      <Form.Check
                        inline
                        label="NO"
                        name="group1"
                        type={'radio'}
                        id={`inline-radio-2`}
                        style={{ fontSize: '13px' }}
                        checked={!IsPan}
                        // value={IsPekrn}
                        onChange={() => {
                          setIsPan(false);
                          setIsPekrn(!IsPekrn);
                        }}
                      />
                    </div>
                  )}

                  <InputTextHook
                    type="password"
                    register={register}
                    // name="panPekrnNo"
                    name={fieldName[2]}
                    // label="PAN/PEKRN no."
                    label={IsPan ? 'PAN no.' : 'PEKRN no.'}
                    placeholder="PAN/PEKRN no."
                    reqText={IsPan ? 'PAN no. required' : 'PEKRN no. required'}
                    disabled={false}
                    errorBorder={errors[fieldName[2]]?.message}
                    mandatory="*"
                    depend={'dummytext'}
                    sts={
                      investorCategory === 'M' &&
                      fieldName[0].split('-')[0] !== 'gurdian'
                    }
                    // value={form?.panPekrnNo.toUpperCase() || ''}
                    // changeFun={formHandeler}
                  />
                  <small style={errorFontStyle}>
                    {errors[fieldName[2]]?.message}
                  </small>
                </Col>
                <Col xs={12} md={12}>
                  <InputTextHook
                    register={register}
                    name={`${fieldName[2]}-conf`}
                    // name={fieldName[3]}
                    label={IsPan ? 'Re-Enter PAN no.' : 'Re-Enter PEKRN no.'}
                    placeholder="PAN/PEKRN no."
                    reqText={
                      IsPan
                        ? 'please Re-Enter PAN no.'
                        : 'please Re-Enter PEKRN no.'
                    }
                    disabled={false}
                    errorBorder={errors[`${fieldName[2]}-conf`]?.message}
                    mandatory="*"
                    // value={form?.confirmpanPekrnNo.toUpperCase() || ''}
                    changeFun={formHandeler}
                    compair={panPekrnNo}
                    depend={'dummytext'}
                    sts={
                      investorCategory === 'M' &&
                      fieldName[0].split('-')[0] !== 'gurdian'
                    }
                  />
                  <small style={errorFontStyle}>
                    {errors[`${fieldName[2]}-conf`]?.message}
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}></Col>
          </Row>
        </GridCustom>
      </Section>
      {/* ===========================Primary Holder Additional KYC Details=========================== */}

      {(investorCategory !== 'M' ||
        fieldName[0].split('-')[0] === 'gurdian') && (
        <Section heading={`${holderType} Additional KYC Details`}>
          <GridCustom>
            <Row className="justify-content-md-center">
              <Col xs={12} md={2} className="m-4">
                <Form.Check
                  type="radio"
                  label="Gross Annual Income"
                  name="income"
                  data-name="GAI"
                  onChange={incomeStatus}
                  mandatory="*"
                  checked={
                    (form?.[fieldName[7]]?.length > 0 || grossIncomeRadio) &&
                    'checked'
                  }
                />
              </Col>
              <Col xs={12} md={2} className="m-4">
                <Form.Check
                  type="radio"
                  label="Networth"
                  name="income"
                  data-name="NW"
                  onChange={incomeStatus}
                  mandatory="*"
                  checked={
                    (form?.[fieldName[8]]?.length > 0 || networthRadio) &&
                    'checked'
                  }
                />
              </Col>
            </Row>
            <Row
              style={{
                display:
                  form?.[fieldName[7]]?.length > 0 || grossIncomeRadio
                    ? 'flex'
                    : 'none',
              }}
            >
              <Col xs={12} md={4}>
                <SelectOptionHook
                  register={register}
                  // name="grossIncome"
                  name={fieldName[7]}
                  label="Gross Annual Income "
                  reqText="please select Gross Annual Income"
                  disabled={false}
                  sts={!form?.[fieldName[7]]?.length > 0 && !grossIncomeRadio}
                  mandatory="*"
                  errorBorder={
                    // !form?.otherDetail?.grossIncome &&
                    errors[fieldName[7]]?.message
                  }
                  listOptions={grossAnnualIncomeOptions}
                  // value={form?.otherDetail?.grossIncome || ''}
                  changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {
                    // !form?.otherDetail?.grossIncome &&
                    errors[fieldName[7]]?.message
                  }
                </small>
              </Col>
            </Row>
            <Row
              style={{
                display:
                  form?.[fieldName[8]]?.length > 0 || networthRadio
                    ? 'flex'
                    : 'none',
              }}
            >
              <Col xs={12} md={4}>
                <InputTextHook
                  register={register}
                  // name="netWorth"
                  name={fieldName[8]}
                  label="Networth (in Rs.)"
                  reqText="please enter Networth (in Rs.)"
                  disabled={false}
                  sts={!form?.[fieldName[8]]?.length > 0 && !networthRadio}
                  depend="netWorth"
                  errorBorder={errors[fieldName[8]]?.message}
                  mandatory="*"
                  // value={form?.otherDetail?.netWorth || ''}
                  // changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {errors[fieldName[8]]?.message}
                </small>
              </Col>
              <Col xs={12} md={4}>
                <InputTextHook
                  type="date"
                  register={register}
                  // name="netWorthDate"
                  name={fieldName[9]}
                  label="As on date"
                  reqText="pleas select date"
                  disabled={false}
                  sts={!form?.[fieldName[8]]?.length > 0 && !networthRadio}
                  depend="netWorthDate"
                  errorBorder={errors[fieldName[9]]?.message}
                  mandatory="*"
                  // value={form?.otherDetail?.netWorthDate || ''}
                  // changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {errors[fieldName[9]]?.message}
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <SelectOptionHook
                  register={register}
                  // name="sourceOfWealth"
                  name={fieldName[10]}
                  label="Source of Wealth"
                  reqText="please select source of wealth"
                  disabled={false}
                  mandatory="*"
                  errorBorder={
                    // !form?.otherDetail?.sourceOfWealth &&
                    errors[fieldName[10]]?.message
                  }
                  listOptions={sourceOfWealthOptions}
                  // value={form?.otherDetail?.sourceOfWealth || ''}
                  changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {
                    // !form?.otherDetail?.sourceOfWealth &&
                    errors[fieldName[10]]?.message
                  }
                </small>
              </Col>
              <Col xs={12} md={4}>
                <InputTextHook
                  register={register}
                  // name="sourceOfWealthOthers"
                  name={fieldName[11]}
                  label="Other"
                  reqText="please enter other source of welth"
                  disabled={false}
                  depend={fieldName[11]}
                  sts={isOtherSourceOfWealth}
                  errorBorder={errors[fieldName[11]]?.message}
                  mandatory="*"
                  // value={form?.otherDetail?.sourceOfWealthOthers || ''}
                  // changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {errors[fieldName[11]]?.message}
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <SelectOptionHook
                  register={register}
                  // name="occupation"
                  name={fieldName[12]}
                  label="Occupation"
                  reqText="please select occupation"
                  disabled={false}
                  mandatory="*"
                  errorBorder={
                    !form?.otherDetail?.occupation &&
                    errors[fieldName[12]]?.message
                  }
                  listOptions={occupationOptions}
                  // value={form?.otherDetail?.occupation || ''}
                  changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {!form?.otherDetail?.occupation &&
                    errors[fieldName[12]]?.message}
                </small>
              </Col>
              <Col xs={12} md={4}>
                <InputTextHook
                  register={register}
                  // name="occupationOthers"
                  name={fieldName[13]}
                  label="Other"
                  reqText="please enter other ocupation"
                  disabled={false}
                  sts={isOtherOccupation}
                  depend={fieldName[12]}
                  errorBorder={errors[fieldName[13]]?.message}
                  mandatory="*"
                  // value={form?.otherDetail?.occupationOthers || ''}
                  // changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {errors[fieldName[13]]?.message}
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <SelectOptionHook
                  register={register}
                  // name="pep"
                  name={fieldName[14]}
                  label="Political Exposure"
                  reqText="please select Political Exposure"
                  disabled={false}
                  mandatory="*"
                  errorBorder={errors[fieldName[14]]?.message}
                  listOptions={politicalExposureOptions}
                  // value={form?.otherDetail?.pep || ''}
                  changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {errors[fieldName[14]]?.message}
                </small>
              </Col>
              <Col xs={12} md={4}>
                <SelectOptionHook
                  register={register}
                  // name="kraAddressType"
                  name={fieldName[15]}
                  label="KRA Address Type"
                  reqText="please select KRA Address Type"
                  disabled={false}
                  mandatory="*"
                  errorBorder={
                    !form?.otherDetail?.kraAddressType &&
                    errors[fieldName[15]]?.message
                  }
                  listOptions={addressTypeOptions}
                  // value={form?.otherDetail?.kraAddressType || ''}
                  changeFun={formHandeler}
                />
                <small style={errorFontStyle}>
                  {!form?.otherDetail?.kraAddressType &&
                    errors[fieldName[15]]?.message}
                </small>
              </Col>
            </Row>
          </GridCustom>
        </Section>
      )}

      {/* ===========================Primary Holder FATCA Details=========================== */}
      <Section heading={`${holderType} FATCA Details`}>
        <GridCustom>
          <Row>
            <Col xs={12} md={4}>
              <SelectOptionHook
                register={register}
                // name="taxResidencyFlag"
                name={fieldName[16]}
                label="Tax Residency in a country other than India? "
                reqText="Tax Residency in a country required"
                disabled={false}
                mandatory="*"
                errorBorder={errors[fieldName[16]]?.message}
                listOptions={taxResidencyOptions}
                value={taxResidency}
                changeFun={formHandeler}
              />
              <small style={errorFontStyle}>
                {errors[fieldName[16]]?.message}
              </small>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <InputTextHook
                register={register}
                // name="birthCity"
                name={fieldName[17]}
                label="Place of Birth"
                reqText="please enter Place of Birth"
                disabled={false}
                errorBorder={errors[fieldName[17]]?.message}
                mandatory="*"
                // value={form?.fatcaDetail?.birthCity || ''}
                // changeFun={formHandeler}
              />
              <small style={errorFontStyle}>
                {errors[fieldName[17]]?.message}
              </small>
            </Col>
            <Col xs={12} md={3}>
              <SelectSearchHook
                register={register}
                // name="birthCountry"
                name={fieldName[18]}
                selectFieldName={fieldName[18].split('-')[0]}
                label="Country of Birth"
                reqText="country of Birth required"
                mandatory="*"
                errorBorder={
                  !form?.fatcaDetail?.birthCountry &&
                  errors[fieldName[18]]?.message
                }
                options={countryListOptions}
                // value={form?.[fieldName[18]] || ''}
                value={
                  countryListOptions.find((item) =>
                    item.value === form?.[fieldName[18]] ? item.label : ''
                  )?.label
                }
                setBlanket={setBlanket}
                blanket={blanket}
                // flag={form?.fatcaDetail?.taxResidencyFlag}
                form={form}
                setForm={setForm}
                // changeFun={formHandeler}
                setValue={setValue}
                sts={true}
                // depend={fieldName[18]}
              />
              <small style={errorFontStyle}>
                {!form?.fatcaDetail?.birthCountry &&
                  errors[fieldName[18]]?.message}
              </small>
            </Col>
            <Col xs={12} md={3}>
              <SelectSearchHook
                register={register}
                // name="citizenshipCountry"
                name={fieldName[19]}
                selectFieldName={fieldName[19].split('-')[0]}
                label="Country of Citizenship"
                reqText="country of citizenship required"
                mandatory="*"
                errorBorder={
                  !form?.fatcaDetail?.citizenshipCountry &&
                  errors[fieldName[19]]?.message
                }
                options={countryListOptions}
                // value={form?.[fieldName[19]] || ''}
                value={
                  countryListOptions.find((item) =>
                    item.value === form?.[fieldName[19]] ? item.label : ''
                  )?.label
                }
                setBlanket={setBlanket}
                blanket={blanket}
                flag={form?.fatcaDetail?.taxResidencyFlag}
                form={form}
                setForm={setForm}
                // changeFun={formHandeler}
                setValue={setValue}
                sts={true}
                depend={fieldName[19]}
              />
              <small style={errorFontStyle}>
                {!form?.fatcaDetail?.citizenshipCountry &&
                  errors[fieldName[19]]?.message}
              </small>
            </Col>
            <Col xs={12} md={3}>
              <SelectSearchHook
                register={register}
                // name="nationalityCountry"
                name={fieldName[20]}
                selectFieldName={fieldName[20].split('-')[0]}
                label="Country of Nationality"
                reqText="country of nationality required"
                mandatory="*"
                errorBorder={
                  !form?.fatcaDetail?.nationalityCountry &&
                  errors[fieldName[20]]?.message
                }
                options={countryListOptions}
                // value={form?.[fieldName[20]] || ''}
                value={
                  countryListOptions.find((item) =>
                    item.value === form?.[fieldName[20]] ? item.label : ''
                  )?.label
                }
                setBlanket={setBlanket}
                blanket={blanket}
                flag={form?.fatcaDetail?.taxResidencyFlag}
                form={form}
                setForm={setForm}
                // changeFun={formHandeler}
                setValue={setValue}
                sts={true}
                depend={fieldName[20]}
              />
              <small style={errorFontStyle}>
                {!form?.fatcaDetail?.nationalityCountry &&
                  errors[fieldName[20]]?.message}
              </small>
            </Col>
          </Row>
          {taxResidency === 'Y' &&
            textRecords?.map((fildsObj, ind) => {
              return (
                <NonIndianResidence
                  key={ind}
                  rowIndex={ind}
                  selectFieldName={fieldName[21].split('-')[0]}
                  register={register}
                  fildsObj={fildsObj}
                  form={form}
                  sts={taxResidency === 'Y'}
                  setForm={setForm}
                  setValue={setValue}
                  setBlanket={setBlanket}
                  blanket={blanket}
                  options={countryListOptions}
                  errors={errors}
                  errorStyle={errorFontStyle}
                  taxResidency={taxResidency}
                  removeRecordRow={removeRecordRow}
                  setTextRecords={setTextRecords}
                  textRecords={textRecords}
                  watch={watch}
                  getValues={getValues}
                />
              );
            })}

          {taxResidency === 'Y' && (
            <Button size="sm" onClick={addRecordRow}>
              Add More
            </Button>
          )}
        </GridCustom>
      </Section>
      <br></br>
      {/* <FooterSection
        backBtn={true}
        nextBtn={true}
        btnFun={btnFun}
        cls="btn-right-align"
      /> */}
    </React.Fragment>
  );
}

export default StakeHolder;
