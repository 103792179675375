export const secondaryFormFields = {
  'secondary-name': '',
  'secondary-dateOfBirth': '',
  'secondary-panPekrnNo': '',
  'secondary-panExemptFlag': '',
  // 'secondary-confirmpanPekrnNo': '',
  'secondary-mobileIsdCode': '',
  'secondary-primaryMobileNo': '',
  'secondary-primaryEmail': '',
  // 'secondary-primaryMobileBelongsTo': 'A',
  // 'secondary-primaryEmailBelongsTo': 'A',
  'secondary-grossIncome': '',
  'secondary-netWorth': '',
  'secondary-netWorthDate': '',
  'secondary-sourceOfWealth': '',
  'secondary-sourceOfWealthOthers': '',
  'secondary-occupation': '',
  'secondary-occupationOthers': '',
  'secondary-kraAddressType': '',
  'secondary-pep': '',
  'secondary-taxResidencyFlag': 'N',
  'secondary-birthCity': '',
  'secondary-birthCountry': '',
  'secondary-citizenshipCountry': '',
  'secondary-nationalityCountry': '',
  // 'secondary-sequenceNo': 0,
  'secondary-taxRecords': [
    {
      'taxCountry': 'kk',
      'taxReferenceNo': 'hh',
      'identityType': 'll',
    },
  ],
};
