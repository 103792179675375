export const natureOptions = [
  {
    value: '',
    label: 'Select Holding Nature',
  },
  {
    value: 'SI',
    label: 'Single',
  },
  {
    value: 'JO',
    label: 'Joint',
  },
  {
    value: 'AS',
    label: 'Anyone or Survivor',
  },
];

export const investorOptions = [
  {
    value: '',
    label: 'Select Investor Category',
  },
];

export const singleOptions = [
  {
    value: '',
    label: 'Select Investor Category',
  },
  {
    value: 'I',
    label: 'Individual',
  },
  {
    value: 'M',
    label: 'Minor',
  },
  {
    value: 'S',
    label: 'Sole-proprietor',
  },
];
 
export const jointOptions = [
  {
    value: '',
    label: 'Select Investor Category',
  },
  {
    value: 'I',
    label: 'Individual',
  },
];

export const singleIndividualOptions = [
  {
    value: '',
    label: 'Select Tax Status',
  },
  {
    value: 'RI',
    label: '01-RES.IND',
  },
  {
    value: 'NRI',
    label: '02-NRI-NRE',
  },
  {
    value: 'NNI',
    label: '03-NRI-NRO ',
  },
  {
    value: 'NPI',
    label: '05-PIO',
  },
  {
    value: 'PME',
    label: '05-PIO NRE',
  },
  {
    value: 'PMO',
    label: '05-PIO NRO',
  },
];

export const singleMinorOptions = [
  {
    value: '',
    label: 'Select Tax Status ',
  },
  {
    value: 'RM',
    label: '01-RES.IND (Minor)',
  },
  {
    value: 'NRM',
    label: '02-NRI-NRE (Minor)',
  },
  {
    value: 'NNM',
    label: '03-NRI-NRO (Minor)',
  },
  {
    value: 'NPM',
    label: '05-PIO (Minor)',
  },
  {
    value: 'PME',
    label: '05-PIO (Minor) NRE',
  },
  {
    value: 'PMO',
    label: '05-PIO (Minor) NRO',
  },
];

export const singleSoleProprietorOptions = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "RS",
    label: "01-RES.IND (Sole-Proprietor)",
  },
];

export const holderOptions = [
  {
    value: "01",
    label: "1",
  },
  {
    value: "02",
    label: "2",
  },
  {
    value: "03",
    label: "3",
  },
];
