import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import '../Style.css';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
//component
import Tabs from '../../common/tabs/Tabs';
import ButtonCustomNew from '../../common/button/ButtonCustomNew';
import Section from '../../common/section/Section';
import GridCustom from '../../common/grid-custom/GridCustom';
import SelectOption from '../../common/form-elements/SelectOption';
import SelectOptionHook from '../../common/form-elements/SelectOptionHook';
import { accountCount } from './accountData';
import BankAccountSection from './BankAccountSection';
import FooterSection from '../../common/footerSection/FooterSection';
import { btnHandeler } from '../../common/helper/Helper';
import { tabUpdate, pageCount } from '../../reducer/Reducer/tab/tabSlice';
import { isSubmitted } from '../bank-account/bankaccountSlice'
import SmallMessageModal from '../../common/modal/SmallMessageModal';
import useVerifyStepsSubmission from '../../common/verify-steps-submission/VerifyStepsSubmission';

import {
  accountsFun,
  createBankAccountOBJ,
  getBankNameAsync,
} from './bankaccountSlice';
import {
  SubmitFormDetail,
  submitFormAsync,
  resetAction,
} from '../proof-upload/proofSlice';

const bankRecord = [
  {
    sequenceNo: '1',
    defaultAccountFlag: false,
    accountNo: '',
    accountType: '',
    bankId: '',
    micrCode: '',
    ifscCode: '',
    bankProof: '',
    reAccountNo: '',
  },
];

function BankAccounts({ setSmShow }) {
  const [form, setForm] = useState([]);
  const [number, setNumber] = useState(1);
  const [btnFun, setBtnFun] = useState({});
  const [errorsOld, setErrors] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);
  const [textRecords, setTextRecords] = useState([]);
  const [defaultBankIndex, setDefaultBankIndex] = useState(null);
  // const [smShow, setSmShow] = useState(false);

  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [submitFormData, setSubmitFormData] = useState(false);
  const [blanket, setBlanket] = useState(false);
  const { stepsCount, tabsCreater } = useSelector((state) => state.tab);
  const { canCriteriaObj, canCredential } = useSelector(
    (state) => state.criteria
  );

  const { accountCountNum, bankAccountsObj, banksNameList } = useSelector(
    (state) => state.bankAccount
  );

  const { completeFormObj, isLoading, isSuccess, message, submitForm } =
    useSelector((state) => state.proof);

  const closeBlanketHandeler = () => {
    setBlanket(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  // useFormPersist('form-name-bankAccount', { watch, setValue });
  useEffect(() => {
    dispatch(getBankNameAsync());
  }, []);

  useEffect(() => {
    const filterAccount =
      bankAccountsObj.length > 0 &&
      bankAccountsObj.findIndex(
        (account) => account.defaultAccountFlag === 'Y'
      );

    if (filterAccount !== -1) {
      setDefaultBankIndex(filterAccount);
    } else {
      setDefaultBankIndex(0);
    }
  }, [bankAccountsObj]);

  const numberHandeler = (e) => {
    let val = e.target.value;

    setNumber(val);
    dispatch(accountsFun(val));
  };

  useEffect(() => {
    let listItems = bankAccountsObj.map((item, ind) => {
      return { count: ind, value: item.bankId };
    });

    setTextRecords(listItems);
  }, [bankAccountsObj]);

  const thisAccountHandeler = (e, num) => {
    let name = e.target.name;
    let value = e.target.value;
    let count = e.target.dataset.count;

    let newArray = form.map((obj) => {
      if (obj.sequenceNo === count) {
        return { ...obj, [name]: value };
      }
      return obj;
    });

    let newError = errorsOld.map((item, index) => {
      if (index + 1 === +count) {
        if (!!item[name]) {
          return { ...item, [name]: null };
        }
      }
      return item;
    });

    setErrors(newError);

    setForm(newArray);
  };

  useEffect(() => {
    if (
      canCriteriaObj?.holdingNature === 'SI' &&
      canCriteriaObj?.investorCategory === 'M'
    ) {
      setShowSubmitBtn(true);
    } else {
      setShowSubmitBtn(false);
    }
  }, [canCriteriaObj]);

  useEffect(() => {
    setBtnFun(btnHandeler(dispatch, pageCount, stepsCount));
  }, [dispatch, stepsCount]);

  const { formStepsStatus } = useVerifyStepsSubmission();

  const formSubmitHandeler = (data) => {
    let newObj = [];
    // if (+number === 1 && bankAccountsObj.length === 1) {
    //   setDefaultBankIndex(0);
    // }
    for (let k in data) {
      let defaultValue = textRecords[0]?.value;
      let secondValue = textRecords[1]?.value;
      let thirdValue = textRecords[2]?.value;

      if (k.includes('First')) {
        let lab = k.split('-')[1];

        newObj[0] = {
          ...newObj[0],
          sequenceNo: 1,
          defaultAccountFlag: defaultBankIndex === 0 ? 'Y' : 'N',
          bankId: defaultValue,
          [lab]: data[k],
        };
      }

      if (k.includes('Second')) {
        let lab = k.split('-')[1];

        newObj[1] = {
          ...newObj[1],
          sequenceNo: 2,
          defaultAccountFlag: defaultBankIndex === 1 ? 'Y' : 'N',
          bankId: secondValue,
          [lab]: data[k],
        };
      }
      if (k.includes('Third')) {
        let lab = k.split('-')[1];
        newObj[2] = {
          ...newObj[2],
          sequenceNo: 3,
          defaultAccountFlag: defaultBankIndex === 2 ? 'Y' : 'N',
          bankId: thirdValue,
          [lab]: data[k],
        };
      }
    }

    if (formStepsStatus.length > 1) {
      setSmShow(true);
      return;
    }
    setBankAccount(newObj);
    for (let k in newObj) {
      console.log(newObj[k].reAccountNo);
      delete newObj[k].reAccountNo;
    }
    dispatch(createBankAccountOBJ(newObj.slice(0, number)));
    // form submit button goes here

    dispatch(
      SubmitFormDetail({
        ...completeFormObj,
        bankRecords: newObj.slice(0, number),
      })
    );

    //=====================

    const filterAccount = (account) =>
      account.defaultAccountFlag === 'Y' || +number === 1;
    const checkDefaultBank = newObj.slice(0, number).some(filterAccount);

    if (showSubmitBtn) {
      // if (!checkDefaultBank) {
      //   setSmShow(true);
      //   return;
      // }

      dispatch(submitFormAsync(completeFormObj));
      setSubmitFormData(true);
      // dispatch(pageCount(stepsCount + 1));
    } else {
      console.log('with NO submit button');
      // if (!checkDefaultBank) {
      //   setSmShow(true);
      //   return;
      // }

      dispatch(pageCount(stepsCount + 1));
    }
  };

  useEffect(() => {
    setValue('accounts', bankAccountsObj?.length || bankRecord?.length);
  }, []);

  useEffect(() => {
    if (blanket) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [blanket]);

  useEffect(() => {
    dispatch(accountsFun(bankAccountsObj?.length));
    if (bankAccountsObj.length > 0) {
      dispatch(accountsFun(1));
    }

    setNumber(bankAccountsObj?.length || 1);
  }, [bankAccountsObj?.length]);

  const backBtnHandeler = () => {
    dispatch(pageCount(stepsCount - 1));
  };

  const defaultBankHandeler = (count) => {
    setDefaultBankIndex(count);
  };

  useEffect(() => {
    if (!isLoading) {
      if (submitForm.code === 1) {
        if (canCredential?.skipNomineeVerification) {
          const shown = tabsCreater.filter((i) => i.show === true);
          dispatch(isSubmitted(false));
          dispatch(pageCount(shown.length - 1));
          toast.success('form submitted successfully');

          return;
        }
        dispatch(pageCount(stepsCount + 1));
        toast.success('form submitted successfully');

        setSubmitFormData(false);
        // dispatch(resetAction());
      } else {
        if (submitFormData) {
          toast.error(
            submitForm.message || message[0] || 'somthing went wrong'
          );
          setSubmitFormData(false);
        }
      }
    }
  }, [submitForm.code, isLoading]);

  useEffect(() => {
    if (!bankAccountsObj.length > 0) {
      setDefaultBankIndex(0);
    }
  }, []);

  return (
    <Container>
      <div>
        {blanket && (
          <div onClick={closeBlanketHandeler} className="blanket-cover"></div>
        )}
      </div>

      {/* {smShow && (
        <SmallMessageModal
          smShow={smShow}
          setSmShow={setSmShow}
          title="Account details"
          message="Please mention your default bank"
        />
      )} */}

      <Tabs />

      <ButtonCustomNew backFun={backBtnHandeler} />
      <Form onSubmit={handleSubmit(formSubmitHandeler)}>
        <Section heading="Number of bank account">
          <GridCustom>
            <Row>
              <Col xs={12} md={4}>
                <SelectOptionHook
                  register={register}
                  name="accounts"
                  label="Bank Account(s)"
                  // reqText=""
                  disabled={false}
                  mandatory="*"
                  // errorBorder={errors?.holdingNature?.message}
                  listOptions={accountCount}
                  // value={form?.holdingNature || ''}
                  changeFun={numberHandeler}
                />
              </Col>
            </Row>
          </GridCustom>
        </Section>

        {Array.from({
          length: number,
        }).map((detail, index) => {
          return (
            <BankAccountSection
              key={index}
              formObj={bankAccountsObj[index] || bankRecord[index]}
              form={form}
              objLength={bankAccountsObj.length}
              setForm={setForm}
              count={index}
              thisAccountHandeler={thisAccountHandeler}
              errorsOld={errorsOld}
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              banksName={banksNameList}
              setBlanket={setBlanket}
              blanket={blanket}
              textRecords={textRecords}
              setTextRecords={setTextRecords}
              defaultBankHandeler={defaultBankHandeler}
              defaultBankIndex={defaultBankIndex}
              number={+number - 1}
            />
          );
        })}
        <div className="button-container">
          <ButtonCustomNew backFun={backBtnHandeler} />
          {showSubmitBtn ? (
            <ButtonCustomNew text="Submit" />
          ) : (
            <ButtonCustomNew text="next" />
          )}
        </div>
      </Form>
    </Container>
  );
}

export default BankAccounts;
