import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import proofAPI from './proofAPI';

const initialState = {
  proofUploadObj: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
submitForm:{},
canId:'',
completeFormObj:{},
imageUploaded:false
};



export const submitFormAsync = createAsyncThunk(
  'form/post',
  async (obj, thunkAPI) => {
 
    try {
      return await proofAPI.submitFormData(obj);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const createProofDataAsync = createAsyncThunk(
  'proof/create',
  async (obj, thunkAPI) => {
    
    try {
      return await proofAPI.createProofData(obj);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProofDataAsync = createAsyncThunk(
  'proof/get',
  async (userId, thunkAPI) => {
    try {
      return await proofAPI.getProofData(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProofDataAsync = createAsyncThunk(
  'proof/update',
  async (obj, thunkAPI) => {
    try {
      return await proofAPI.updateProofData(obj);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const proofSlice = createSlice({
  name: 'proof',
  initialState,
  reducers: {
    reset: (state) => {
      state.proofUploadObj = {};
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    SubmitFormDetail: (state, action) => {
      state.completeFormObj = action.payload;
    },
    resetAction: (state, action) => {
      state.isSuccess = false;
      state.submitForm = {};
      state.completeFormObj= {}
    },
    proofUploadFun: (state, action) => {
      state.imageUploaded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      //submit form
      .addCase(submitFormAsync.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(submitFormAsync.fulfilled, (state, action) => {
       
        state.isLoading = false;
        state.submitForm = action.payload;
        state.canId = action.payload.can;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(submitFormAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      //create primary form
      .addCase(createProofDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProofDataAsync.fulfilled, (state, action) => {
        
        state.isLoading = false;
        state.proofUploadObj = action.payload;
        state.isError = false;
        state.message = '';
      })
      .addCase(createProofDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //get primary form
      .addCase(getProofDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProofDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.proofUploadObj = action.payload;
        state.isError = false;
        state.message = '';
      })
      .addCase(getProofDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //update primary form
      .addCase(updateProofDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProofDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.proofUploadObj = action.payload;
        state.isError = false;
        state.message = '';
      })
      .addCase(updateProofDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

const { actions, reducer } = proofSlice;

export const { reset, SubmitFormDetail, resetAction, proofUploadFun } = actions;
export default reducer;
