import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// component
import InputTextHook from '../../common/form-elements/InputTextHook';
import GridCustom from '../../common/grid-custom/GridCustom';
import Section from '../../common/section/Section';
import InputText from '../../common/form-elements/InputText';
import DatePicker from '../../common/form-elements/DatePicker';

const errorFontStyle = {
  color: 'red',
  fontSize: '12px',
  height: '12px',
  display: 'block',
};

export default function AddNominee({
  count,
  formObj,
  setForm,
  errors,
  thisAccountHandeler,
  percentSts,
  register,
  setValue,
  getValues,
  watch,
}) {
  const [isMinor, setIsMinor] = useState(true);

  let order = count === 0 ? '1st' : count === 1 ? '2nd' : '3rd';

  useEffect(() => {
    setValue(`${order}-nomineeName`, formObj?.nomineeName);
    setValue(`${order}-relation`, formObj?.relation);
    setValue(`${order}-percentage`, formObj?.percentage);
    setValue(`${order}-dateOfBirth`, formObj?.dateOfBirth);
    setValue(`${order}-guardianName`, formObj?.guardianName);
    setValue(`${order}-guardianRelationship`, formObj?.guardianRelationship);
    setValue(`${order}-guardianDateOfBirth`, formObj?.guardianDateOfBirth);
  }, []);

  let labelName = `${order}-dateOfBirth`;
  const refNumber = watch(labelName);

  useEffect(() => {
    const records = (birthday) => {
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const timer = setTimeout(() => {
      const getAge = records(new Date(refNumber));

      if (refNumber?.length) {
        if (getAge > 18) {
          setIsMinor(true);
        } else {
          setIsMinor(false);
        }
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [refNumber]);

  return (
    <Section heading={`${order} Nominee`}>
      <GridCustom>
        <Row>
          <Col xs={12} md={3}>
            <InputTextHook
              type="text"
              register={register}
              name={`${order}-nomineeName`}
              label="Name of Nominee"
              reqText="name required"
              disabled={false}
              errorBorder={errors[[`${order}-nomineeName`]]?.message}
              mandatory="*"
              // value={form?.name || ''}
              // changeFun={thisAccountHandeler}
            />
            <small style={errorFontStyle}>
              {errors[`${order}-nomineeName`]?.message}
            </small>
          </Col>
          <Col xs={12} md={3}>
            <InputTextHook
              type="text"
              register={register}
              name={`${order}-relation`}
              label="Relationship"
              reqText="relationship required"
              disabled={false}
              errorBorder={errors[`${order}-relation`]?.message}
              mandatory="*"
              // value={form?.name || ''}
              // changeFun={thisAccountHandeler}
            />
            <small style={errorFontStyle}>
              {errors[`${order}-relation`]?.message}
            </small>
          </Col>
          <Col xs={12} md={3}>
            <InputTextHook
              type="text"
              register={register}
              name={`${order}-percentage`}
              label="Percent(%)"
              reqText="Percent(%) required"
              disabled={false}
              errorBorder={errors[`${order}-percentage`]?.message || percentSts}
              mandatory="*"
              // value={form?.name || ''}
              // changeFun={thisAccountHandeler}
            />
            <small style={errorFontStyle}>
              {percentSts ? 'total percentage should be 100' : ''}

              {errors[`${order}-percentage`]?.message}
            </small>
          </Col>
          <Col xs={12} md={3}>
            <InputTextHook
              type="date"
              register={register}
              name={`${order}-dateOfBirth`}
              label="Date of Birth"
              reqText="date required"
              disabled={false}
              errorBorder={errors[`${order}-dateOfBirth`]?.message}
              mandatory="*"
              // value={form?.dateOfBirth || ''}
              // changeFun={formHandeler}
            />
            <small style={errorFontStyle}>
              {errors[`${order}-dateOfBirth`]?.message}
            </small>
          </Col>
        </Row>
        {!isMinor && (
          <Row>
            <Col xs={12} md={3}>
              <InputTextHook
                type="text"
                register={register}
                name={`${order}-nomineeGuardianName`}
                label="Name of Guardian"
                reqText="Guardian name required"
                disabled={false}
                errorBorder={errors[[`${order}-nomineeGuardianName`]]?.message}
                mandatory="*"

                // value={form?.name || ''}
                // changeFun={thisAccountHandeler}
              />
              <small style={errorFontStyle}>
                {errors[`${order}-nomineeGuardianName`]?.message}
              </small>
            </Col>
            <Col xs={12} md={3}>
              <InputTextHook
                type="text"
                register={register}
                name={`${order}-nomineeGuardianRelation`}
                label="Guardian Relationship"
                reqText="Guardian Relationship required"
                disabled={false}
                errorBorder={
                  errors[[`${order}-nomineeGuardianRelation`]]?.message
                }
                mandatory="*"
                // value={form?.name || ''}
                // changeFun={thisAccountHandeler}
              />
              <small style={errorFontStyle}>
                {errors[`${order}-nomineeGuardianRelation`]?.message}
              </small>
            </Col>
            <Col xs={12} md={3}>
              <InputTextHook
                type="date"
                register={register}
                name={`${order}-nomineeGuardianDob`}
                label="Guardian Date of Birth"
                reqText="Guardian birth date required"
                disabled={false}
                errorBorder={errors[`${order}-nomineeGuardianDob`]?.message}
                mandatory="*"
                checkMinor={true}
                // value={form?.dateOfBirth || ''}
                // changeFun={formHandeler}
              />
              <small style={errorFontStyle}>
                {errors[`${order}-nomineeGuardianDob`]?.message}
              </small>
            </Col>
          </Row>
        )}
      </GridCustom>
    </Section>
  );
}
