export const primaryFormFields = {
  'primary-name': '',
  'primary-dateOfBirth': '',
  'primary-panPekrnNo': '',
  'primary-panExemptFlag': '',
  // 'primary-confirmpanPekrnNo': '',
  'primary-mobileIsdCode': '',
  'primary-primaryMobileNo': '',
  'primary-primaryEmail': '',
  // 'primary-primaryMobileBelongsTo': 'A',
  // 'primary-primaryEmailBelongsTo': 'A',
  'primary-grossIncome': '',
  'primary-netWorth': '',
  'primary-netWorthDate': '',
  'primary-sourceOfWealth': '',
  'primary-sourceOfWealthOthers': '',
  'primary-occupation': '',
  'primary-occupationOthers': '',
  'primary-pep': '',
  'primary-kraAddressType': '',
  'primary-taxResidencyFlag': 'N',
  'primary-birthCity': '',
  'primary-birthCountry': '',
  'primary-citizenshipCountry': '',
  'primary-nationalityCountry': '',
  // 'primary-sequenceNo': 0,
  'primary-taxRecords': [
    {
      'taxCountry': 'kk',
      'taxReferenceNo': 'hh',
      'identityType': 'll',
    },
  ],
};


