import React from "react";
import Container from "react-bootstrap/Container";


function GridCustom({ children }) {
  return (
    <Container>
     {children}
    </Container>
  );
}

export default GridCustom;
