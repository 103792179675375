import React, { useState, useEffect, useRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import InputText from '../form-elements/InputText';
import InputTextHook from './InputTextHook';
import './style-form-element.css';

function SelectSearchHook({
  name,
  label,
  options,
  blanket,
  setBlanket,
  mandatory,
  errors,
  value,
  changeFun,
  form,
  setForm,
  flag,
  errorBorder,
  register,
  reqText,
  pattern,
  condition,
  setValue,
  sts,
  depend,
  selectFieldName,
  rowIndex,
  fildsObj,
  textRecords,
  setTextRecords,
  thisObj,
  setThisObj,
}) {
  const [isCountryList, setIsCountryList] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState('Select Country');
  const [countryListTop, setCountryListTop] = useState(0);

  const divHeight = useRef(null);

  useEffect(() => {
    setCountryList(options);
  }, [options]);

  const countryListHandeler = () => {
    setIsCountryList(!isCountryList);
    setBlanket(true);
  };

  const filterListHandeler = (e) => {
    let val = e.target.value.toLowerCase();
    let filterLst = options.filter((item) =>
      item.label.toLowerCase().startsWith(val)
    );
    setCountryList(filterLst);
  };

  const selectCountryHandeler = (inputFieldName, countryName, countryValue) => {
    // console.log(
    //   'select value==',
    //   selectFieldName + '-' + inputFieldName,
    //   countryName,
    //   countryValue
    // );
    // setValue(inputFieldName || name, countryName);

    if (
      inputFieldName === 'birthCountry' ||
      inputFieldName === 'citizenshipCountry' ||
      inputFieldName === 'nationalityCountry'
    ) {
      // console.log(countryValue);
      setForm({
        ...form,
        [selectFieldName + '-' + inputFieldName]: countryValue,
      });
    } else if (inputFieldName === 'bankId') {
      // console.log('need ', textRecords, rowIndex, countryName, countryValue);

      // console.log('updateObj', {
      //   label: countryName,
      //   value: countryValue,
      //   count: rowIndex,
      // });
      setThisObj({
        label: countryName,
        value: countryValue,
        count: rowIndex,
      });
      setTextRecords([
        ...textRecords,
        { label: countryName, value: countryValue, count: rowIndex },
      ]);
    } else {
      const orgName = name.split('-')[1];

      let updateRecords = textRecords.map((items, ind) => {
        if (ind === rowIndex) {
          return { ...items, [orgName]: countryValue };
        }
        return items;
      });

      setTextRecords(updateRecords);
    }

    setCountryName(countryName);
    setIsCountryList(false);
    setCountryList(options);
    setBlanket(false);
  };

  useEffect(()=>{
    if (countryListTop > 600 && blanket) {
      window.scrollBy(0, 150);
    }
  })

  useEffect(() => {
    if (!blanket) {
      setIsCountryList(false);
    } 
  }, [blanket]);

  return (
    <article
      className="country-wrapper"
      style={{ zIndex: isCountryList ? 9 : 7 }}
    >
      <div className="search-select-option">
        <InputTextHook
          register={register}
          name={name}
          label={label}
          placeholder={'countryName'}
          // value={
          //   countryList.find((item) =>
          //     item.value === name ? item.label : ''
          //   ).label
          // }
          value={!sts ? 'India' : value || ''}
          clickFun={countryListHandeler}
          changeFun={changeFun}
          errorBorder={errorBorder}
          disabled={flag === 'N' ? true : false}
          reqText={reqText}
          sts={!sts ? true : false}
          depend={name}
        />
        {/* <InputText
          name={name}
          label={label}
          placeholder={countryName}
          value={flag === 'N' ? 'India' : value || countryName}
          clickFun={countryListHandeler}
          changeFun={changeFun}
          mandatory="*"
          errors={true}
          disabled={flag === 'N' ? true : false}
          {...register(name, {
            required: reqText,
            pattern: pattern && {
              value: condition.value,
              message: condition.message,
            },
          })}
         
        /> */}

        <span className="country-arrow">
          <MdKeyboardArrowDown />
        </span>
      </div>

      {isCountryList && (
        <div
          className="country-list-container"
          ref={(el) => {
            setCountryListTop(el?.getBoundingClientRect().top);
          }}
        >
          <div>
            <input
              className="form-control"
              type="text"
              placeholder="Search..."
              onChange={filterListHandeler}
            ></input>
          </div>
          <div className="country-list">
            {countryList?.map((country) => (
              <div
                key={country.label}
                style={{ textTransform: 'capitalize' }}
                onClick={() =>
                  selectCountryHandeler(
                    name.split('-')[1],
                    country.label,
                    country.value
                  )
                }
              >
                {country.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </article>
  );
}

export default SelectSearchHook;
