import axios from 'axios';
import { BASE_URL} from '../../utils/constants';
// bank proof 
export const getBanks = async () => {
  const response = await axios.get(`${BASE_URL}/masters?types=banks`);
  const data = await response.data.banks;

  
  let newData = [{ value: '',  label: 'Select Bank Name' }];

  for (let k of data) {
    newData.push({ value: k.bankId, label: k.bankName });
  }

  if (data) {
    return newData;
  }
  return [];
};

const bankAccountAPI = {
  getBanks,
};
export default bankAccountAPI;
