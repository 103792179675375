import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

//components
import Tabs from '../../common/tabs/Tabs';
import ButtonCustomNew from '../../common/button/ButtonCustomNew';
import StakeHolder from '../../common/stake-holder/StakeHolder';
import { tabUpdate, pageCount } from '../../reducer/Reducer/tab/tabSlice';

import { deleteSecondHolderObj } from '../second-holder/SecondSlice';
import { deleteThirdHolderObj } from '../third-holder/thirdSlice';
import { deleteGuardianHolderObj } from '../guardian-holder/gurdianSlice';
import { deleteNomineeObj, nomineeCountAction } from '../nominees/nomineeSlice';
import SmallMessageModal from '../../common/modal/SmallMessageModal'
import useVerifyStepsSubmission from '../../common/verify-steps-submission/VerifyStepsSubmission';



import {
  createPrimaryHolderOBJ,
  changeTaxResidency,
  deletePrimaryHolderObj,
} from './primarySlice';
import { primaryFormFields } from './primaryData';
// import { createPrimaryHolderOBJ } from './primarySlice';
import { SubmitFormDetail } from '../proof-upload/proofSlice';

const fieldName = Object.keys(primaryFormFields);

const recordsObj = {
  taxCountry: '',
  taxReferenceNo: '',
  identityType: '',
};

function PrimaryHolder({ methods, setSmShow }) {
  const [form, setForm] = useState({});
  const [errorsOld, setErrors] = useState({});
  const [textRecords, setTextRecords] = useState([]);
  const [grossIncomeRadio, setGrossIncomeRadio] = useState(false);
  const [networthRadio, setNetworthRadio] = useState(false);
  // const [IsPan, setIsPan] = useState(false);
  const [IsPan, setIsPan] = useState(false);
  const [IsPekrn, setIsPekrn] = useState(false);
  // const [smShow, setSmShow] = useState(false);

  const { primeHolderObj, taxResidency } = useSelector(
    (state) => state.primary
  );
  const { stepsCount, tabsCreater } = useSelector((state) => state.tab);
  const { canCriteriaObj } = useSelector((state) => state.criteria);
  const { secondHolderObj } = useSelector((state) => state.second);
  const { thirdHolderObj } = useSelector((state) => state.third);
  const { guardianHolderObj } = useSelector((state) => state.guardian);
  const { nomineeObj } = useSelector((state) => state.nominee);
  const { completeFormObj } = useSelector((state) => state.proof);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  // useFormPersist('form-name-primary', { watch, setValue });

  const { formStepsStatus } = useVerifyStepsSubmission();

  useEffect(() => {
    const newObj = {};

    if (Object.keys(primeHolderObj).length > 0) {
      setIsPan(
        primeHolderObj.panExemptFlag !== ''
          ? primeHolderObj.panExemptFlag !== 'N'
            ? false
            : true
          : false
      );

      for (let fstLevel in primeHolderObj) {
        if (fstLevel === 'contactDetail') {
          for (let secLev in primeHolderObj[fstLevel]) {
            newObj[`primary-${secLev}`] = primeHolderObj[fstLevel][secLev];
          }
        } else if (fstLevel === 'otherDetail') {
          for (let secLev in primeHolderObj[fstLevel]) {
            newObj[`primary-${secLev}`] = primeHolderObj[fstLevel][secLev];
          }
        } else if (fstLevel === 'fatcaDetail') {
          for (let secLev in primeHolderObj[fstLevel]) {
            newObj[`primary-${secLev}`] = primeHolderObj[fstLevel][secLev];
          }
        } else {
          newObj[`primary-${fstLevel}`] = primeHolderObj[fstLevel];
        }
      }

      setForm(newObj);
    } else {
      setForm(primaryFormFields);
    }
    if (primeHolderObj?.fatcaDetail?.taxRecords?.length > 0) {
      setTextRecords(primeHolderObj?.fatcaDetail?.taxRecords);
    } else {
      setTextRecords([{ ...recordsObj }]);
    }
  }, [primeHolderObj]);

  // remove secondary third and guardian
  useEffect(() => {
    let filteredHolders = [];

    if (
      (canCriteriaObj?.holdingNature === 'SI' &&
        canCriteriaObj?.investorCategory === 'S') ||
      (canCriteriaObj?.holdingNature === 'SI' &&
        canCriteriaObj?.investorCategory === 'I') ||
      canCriteriaObj?.holdingNature === 'AS'
    ) {
      dispatch(deleteSecondHolderObj());
      dispatch(deleteThirdHolderObj());
      dispatch(deleteGuardianHolderObj());

      filteredHolders = completeFormObj?.holderRecords?.filter((item) => {
        return item.holderType === 'PR';
      });

      // console.log('remove second, third, guardian');
    } else if (
      canCriteriaObj?.holdingNature === 'SI' &&
      canCriteriaObj?.investorCategory === 'M'
    ) {
      // console.log(canCriteriaObj?.investorCategory);
      // dispatch(deletePrimaryHolderObj());
      dispatch(deleteSecondHolderObj());
      dispatch(deleteThirdHolderObj());
      // dispatch(deleteGuardianHolderObj());
      dispatch(deleteNomineeObj());
      dispatch(nomineeCountAction(1));

      ///console.log('remove second, third, nominee');
    } else if (canCriteriaObj?.holdingNature === 'JO') {
      if (canCriteriaObj?.holderCount === 2) {
        dispatch(deleteThirdHolderObj());
        dispatch(deleteGuardianHolderObj());

        filteredHolders = completeFormObj?.holderRecords?.filter((item) => {
          return item.holderType === 'PR' || item.holderType === 'SE';
        });
      } else {
        dispatch(deleteGuardianHolderObj(guardianHolderObj));

        filteredHolders = completeFormObj?.holderRecords?.filter((item) => {
          return (
            item.holderType === 'PR' ||
            item.holderType === 'SE' ||
            item.holderType === 'TH'
          );
        });
      }
    }

    dispatch(
      SubmitFormDetail({
        ...completeFormObj,
        holderRecords: filteredHolders || [],
      })
    );
  }, []);

  useEffect(() => {
    if (
      primeHolderObj?.fatcaDetail?.taxResidencyFlag === 'N' ||
      primeHolderObj?.fatcaDetail?.taxResidencyFlag === '' ||
      Object.keys(primeHolderObj).length === 0
    ) {
      dispatch(changeTaxResidency('N'));
    } else {
      dispatch(changeTaxResidency('Y'));
    }
  }, [primeHolderObj.fatcaDetail?.taxResidencyFlag]);

  const formSubmitHandeler = (data) => {
    const obj = {};

    for (let k in data) {
      if (k.includes('primary-')) {
        let lab = k.split('-')[1];
        // console.log('test==',lab, 'data[k]', data[k], 'form[k]', form[k]);
        if (
          lab === 'birthCountry' ||
          lab === 'citizenshipCountry' ||
          lab === 'nationalityCountry'
        ) {
          obj[lab] = form[k];
        } else {
          obj[lab] = data[k];
        }
      }
    }
    let panValue = IsPan === true ? 'N' : 'Y';

    if (formStepsStatus.length > 1) {
      setSmShow(true);
      return;
    }

    delete obj.taxRecords;

    const submitObj = {
      holderType: 'PR',
      residencePhoneNo: '',
      // relationship: '01',
      // relationshipProof: '01',
      panExemptFlag: panValue,
      panPekrnNo: obj.panPekrnNo,
      name: obj.name,
      dateOfBirth: obj.dateOfBirth,
      contactDetail: {
        primaryEmail: obj.primaryEmail,
        mobileIsdCode: obj.mobileIsdCode,
        primaryMobileBelongsTo: 'S',
        primaryMobileNo: obj.primaryMobileNo,
        primaryEmailBelongsTo: 'S',
      },
      otherDetail: {
        otherInfo: '',
        grossIncome:
          canCriteriaObj?.investorCategory !== 'M' && obj.grossIncome
            ? obj.grossIncome
            : '',
        netWorth:
          canCriteriaObj?.investorCategory !== 'M' && obj.netWorth
            ? obj.netWorth
            : '',
        netWorthDate:
          canCriteriaObj?.investorCategory !== 'M' && obj.netWorthDate
            ? obj.netWorthDate
            : '',
        sourceOfWealth:
          canCriteriaObj?.investorCategory !== 'M' && obj.sourceOfWealth,
        sourceOfWealthOthers:
          canCriteriaObj?.investorCategory !== 'M' && obj.sourceOfWealthOthers,
        occupation: obj.occupation,
        occupationOthers:
          canCriteriaObj?.investorCategory !== 'M' && obj.occupationOthers,
        kraAddressType:
          canCriteriaObj?.investorCategory !== 'M' && obj.kraAddressType,
        pep: obj.pep,
      },
      fatcaDetail: {
        taxResidencyFlag: obj.taxResidencyFlag,
        birthCity: obj.birthCity,
        birthCountry: obj.birthCountry,
        citizenshipCountry: obj.citizenshipCountry,
        nationalityCountry: obj.nationalityCountry,
        taxRecords: textRecords,
      },
    };

    if (obj.taxResidencyFlag === 'N') {
      delete submitObj.fatcaDetail.taxRecords;
    }
    dispatch(createPrimaryHolderOBJ({ ...submitObj }));

    let updateForm = [];
    const isHolderExist = completeFormObj?.holderRecords.some(
      (item) => item.holderType === 'PR'
    );
    if (isHolderExist) {
      updateForm = completeFormObj?.holderRecords?.map((item) => {
        if (item.holderType === 'PR') {
          return submitObj;
        } else {
          return item;
        }
      });
    } else {
      updateForm.push(submitObj);
    }

    dispatch(
      SubmitFormDetail({
        ...completeFormObj,
        holderRecords: updateForm,
      })
    );

    dispatch(pageCount(stepsCount + 1));

    // delete primeHolderObj.confirmpanPekrnNo;
  };

  const backBtnHandeler = () => {
    dispatch(pageCount(stepsCount - 1));
  };

  return (
    <Container>
      <Tabs />

      <Form onSubmit={handleSubmit(formSubmitHandeler)} autoComplete="off">
        <ButtonCustomNew backFun={backBtnHandeler} />
        <StakeHolder
          form={form}
          fieldName={fieldName}
          sliceData={primeHolderObj}
          setForm={setForm}
          holderType={'Primary Holder'}
          errorsOld={errorsOld}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          setErrors={setErrors}
          networthRadio={networthRadio}
          setNetworthRadio={setNetworthRadio}
          grossIncomeRadio={grossIncomeRadio}
          setGrossIncomeRadio={setGrossIncomeRadio}
          IsPan={IsPan}
          setIsPan={setIsPan}
          taxResidency={taxResidency}
          changeTaxResidency={changeTaxResidency}
          investorCategory={canCriteriaObj?.investorCategory}
          textRecords={textRecords}
          setTextRecords={setTextRecords}
          recordsObj={recordsObj}
          IsPekrn={IsPekrn}
          setIsPekrn={setIsPekrn}
        />
        <div className="button-container">
          <ButtonCustomNew backFun={backBtnHandeler} />
          <ButtonCustomNew text="next" />
        </div>
      </Form>
    </Container>
  );
}

export default PrimaryHolder;
