import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  primeHolderObj: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  canId: '',
  taxResidency: 'N',
};

export const primarySlice = createSlice({
  name: 'primary',
  initialState,
  reducers: {
    reset: (state) => {
      state.primeHolderObj = {};
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    changeTaxResidency: (state, action) => {
      state.taxResidency = action.payload;
    },
    deletePrimaryHolderObj: (state, action) => {
      state.primeHolderObj = {};
    },

    createPrimaryHolderOBJ: (state, action) => {
      state.primeHolderObj = action.payload;
    },
  },
});

const { actions, reducer } = primarySlice;
export const {
  reset,
  createPrimaryHolderOBJ,
  changeTaxResidency,
  deletePrimaryHolderObj,
} = actions;
export default reducer;
