export const thirdFormFields = {
  'third-name': '',
  'third-dateOfBirth': '',
  'third-panPekrnNo': '',
  'third-panExemptFlag': '',
  // 'third-confirmpanPekrnNo': '',
  'third-mobileIsdCode': '',
  'third-primaryMobileNo': '',
  'third-primaryEmail': '',
  // 'third-primaryMobileBelongsTo': 'A',
  // 'third-primaryEmailBelongsTo': 'A',
  'third-grossIncome': '',
  'third-netWorth': '',
  'third-netWorthDate': '',
  'third-sourceOfWealth': '',
  'third-sourceOfWealthOthers': '',
  'third-occupation': '',
  'third-occupationOthers': '',
  'third-kraAddressType': '',
  'third-pep': '',
  'third-taxResidencyFlag': 'N',
  'third-birthCity': '',
  'third-birthCountry': '',
  'third-citizenshipCountry': '',
  'third-nationalityCountry': '',
  // 'third-sequenceNo': 0,
  'third-taxRecords': [
    {
      taxCountry: 'kk',
      taxReferenceNo: 'hh',
      identityType: 'll',
    },
  ],
};
