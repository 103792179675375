import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from '../src/assets/loading.gif';

import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

//
import Tabs from './common/tabs/Tabs';
import BankAccounts from './component/bank-account/BankAccounts';
import CanCriteria from './component/can-criteria/CanCriteria';
import GuardianHolder from './component/guardian-holder/GuardianHolder';
import Nominees from './component/nominees/Nominees';
import PrimaryHolder from './component/primary-holder/PrimaryHolder';
import ThirdHolder from './component/third-holder/ThirdHolder';
import ProofUpload from './component/proof-upload/ProofUpload';
import SecondHolder from './component/second-holder/SecondHolder';
import CheckNavigate from './common/check-navigate/CheckNavigate';
import SuccessPage from './component/success-page/SuccessPage';
import useVerifyStepsSubmission from './common/verify-steps-submission/VerifyStepsSubmission';
import { tabUpdate, pageCount } from './reducer/Reducer/tab/tabSlice';
import SmallMessageModal from './common/modal/SmallMessageModal';

const style = {
  fullScreenBox: {
    position: 'absolute',
    background: 'rgba(255,255,255,.8)',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 66,
  },
  centerLoader: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginLeft: '-32px',
  },
};

function App() {
  const [smShow, setSmShow] = useState(false);
  const { isLoading } = useSelector((state) => state.proof);
  const methods = useForm();

  const { formStepsStatus } = useVerifyStepsSubmission();

  return (
    <React.Fragment>
      {isLoading && (
        <div style={style.fullScreenBox}>
          <img src={loader} style={style.centerLoader} alt="loader" />
        </div>
      )}

      {smShow && (
        <SmallMessageModal
          smShow={smShow}
          setSmShow={setSmShow}
          title="Form Details"
          message={formStepsStatus}
        />
      )}
      <FormProvider {...methods}>
        <Router>
          <CheckNavigate />

          <Routes>
            <Route
              path="/can-criteria"
              element={<CanCriteria methods={methods} />}
            />
            <Route
              path="/primary-holder"
              element={
                <PrimaryHolder
                  methods={methods}
                  smShow={smShow}
                  setSmShow={setSmShow}
                />
              }
            />
            <Route
              path="/second-holder"
              element={
                <SecondHolder
                  methods={methods}
                  smShow={smShow}
                  setSmShow={setSmShow}
                />
              }
            />
            <Route
              path="/third-holder"
              element={
                <ThirdHolder
                  methods={methods}
                  smShow={smShow}
                  setSmShow={setSmShow}
                />
              }
            />
            <Route
              path="/guardian-holder"
              element={
                <GuardianHolder
                  methods={methods}
                  smShow={smShow}
                  setSmShow={setSmShow}
                />
              }
            />
            <Route
              path="/bank-accounts"
              element={
                <BankAccounts
                  methods={methods}
                  smShow={smShow}
                  setSmShow={setSmShow}
                />
              }
            />
            <Route
              path="/nominees"
              element={
                <Nominees
                  methods={methods}
                  smShow={smShow}
                  setSmShow={setSmShow}
                />
              }
            />
            <Route
              path="/proof-upload"
              element={<ProofUpload methods={methods} />}
            />
            <Route path="/can-creation-success" element={<SuccessPage />} />
          </Routes>
        </Router>
      </FormProvider>

      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
