import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  guardianHolderObj: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  canId: '',
  taxResidency: 'N',
};

export const guardianSlice = createSlice({
  name: 'guardian',
  initialState,
  reducers: {
    reset: (state) => {
      state.guardianHolderObj = {};
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
    changeTaxResidency: (state, action) => {
      state.taxResidency = action.payload;
    },
    deleteGuardianHolderObj: (state, action) => {
      state.guardianHolderObj = {};
    },

    createGuardianHolderOBJ: (state, action) => {
      state.guardianHolderObj = action.payload;
    },
  },
});

const { actions, reducer } = guardianSlice;

export const {
  reset,
  createGuardianHolderOBJ,
  changeTaxResidency,
  deleteGuardianHolderObj,
} = actions;
export default reducer;
